import React, { useEffect, useState } from "react";
import { useInView } from "react-intersection-observer";
import { useAnimation, motion, easeInOut, easeIn } from "framer-motion";
import { ServiceTabWrapper, TabContent, Tabs, TabWrapper } from "./style";
import { FontDescV1, SubTitle, Title } from "../../components/Headings";
import CreatorImg from "../../static/assets/creator.png";
import CommunityImg from "../../static/assets/communities.png";
import BrandImg from "../../static/assets/brands.png";
import { Button } from "../../components/Button/Button";
import { useIsTab } from "../../utils/utils";
import Dropdown from "../../components/dropdown/Dropdown";
import { REFORM_URL } from "../../utils/constant";
import { fadeInShow } from "../../utils/motion";

export const ServiceTab = () => {
  const [activeTab, setActiveTab] = useState("Community");
  const isTab = useIsTab();
  const [ref, inView] = useInView({
    threshold: 0.2,
  });
  const animation = useAnimation();

  useEffect(() => {
    if (inView) {
      animation.start(fadeInShow("down", "spring", 0.5, 1));
    }

    if (!inView) {
      animation.start({
        y: -50,
        opacity: 0,
      });
    }
  }, [inView]);
  const ServiceTabData = [
    {
      title: "Creators & Celebs",
      type: "creator",
      subTitle: "Monetize your audience through your metaverse",
      info: [
        "Create a sustainable economy using our tools and playbooks",
        "Sell your NFTs in an immersive and interactive virtual environment",
        "Stream your content and organize host events",
        "Earn passive income by renting, staking, and selling your assets",
        "Get sponsorships from brands to sponsor your metaverse home",
      ],
      img: CreatorImg,
    },
    {
      title: "Community",
      type: "community",
      subTitle: "Bring your Web 2 community to the metaverse",
      info: [
        "More immersive way to interact with your community",
        "Incentivize your community by giving them ownership on your Metahome",
        "Transparent pricing and control with you",
        "Exclusive social token to govern the community",
        "Playbooks to build and run your metaverse economy",
      ],
      img: CommunityImg,
    },
    {
      title: "Brands and Corporations",
      type: "brand",
      subTitle: "The next generation internet is here",
      info: [
        "Be an early adopter and build your metaverse presence",
        "Asset marketplace for commerce and brand awareness",
        "Collective ownership, and benefits to the community ",
        "Interoperability to travel and build in various metaverses ",
        "Opportunities to collaborate with metaverses builders",
      ],
      img: BrandImg,
    },
  ];

  const activeSlide = ServiceTabData.filter((item) => item.title === activeTab);
  const constList = [
    "Creators & Celebs",
    "Community",
    "Brands and Corporations",
  ];
  return (
    <ServiceTabWrapper>
      <motion.div ref={ref} animate={animation}>
        <Title>SOCIAL.LI FOR YOU</Title>
        <div className="tag-line">
          <SubTitle>What can you do on Social.li?</SubTitle>
        </div>
      </motion.div>
      <TabWrapper>
        {!isTab ? (
          <motion.div ref={ref} animate={animation}>
            <Tabs
              active={activeTab === "Community"}
              onClick={() => setActiveTab("Community")}
            >
              Community
            </Tabs>
            <Tabs
              active={activeTab === "Brands and Corporations"}
              onClick={() => setActiveTab("Brands and Corporations")}
            >
              Brands and Corporations
            </Tabs>
            <Tabs
              active={activeTab === "Creators & Celebs"}
              onClick={() => setActiveTab("Creators & Celebs")}
            >
              Creators & Celebs
            </Tabs>
          </motion.div>
        ) : (
          <Dropdown
            background="dark"
            selected={activeTab}
            setSelected={(props) => setActiveTab(props)}
            options={constList}
          />
        )}
      </TabWrapper>
      <TabContent>
        <motion.section
          initial={{ opacity: 0 }}
          transition={{
            type: "tween",
            duration: 0.5,
            delay: 1,
            ease: "easeIn",
          }}
          whileInView={{ opacity: 1 }}
        >
          <div className="tab-title">{activeSlide[0].title}</div>
          <div>
            <FontDescV1>{activeSlide[0].subTitle}</FontDescV1>
          </div>
          <ul>
            {activeSlide[0].info.map((item) => {
              return <li key={item}>{item}</li>;
            })}
          </ul>
          <a href={REFORM_URL} target="_blank" className="join-now">
            <Button>Let's do this!</Button>
          </a>
        </motion.section>
        <motion.img
          initial={{ opacity: 0 }}
          transition={{
            type: "tween",
            duration: 0.5,
            delay: 1,
            ease: "easeIn",
          }}
          whileInView={{ opacity: 1 }}
          src={activeSlide[0].img}
          className={`tab-image ${activeSlide[0].type}`}
        />
      </TabContent>
    </ServiceTabWrapper>
  );
};
