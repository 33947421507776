const REFORM_URL = "https://research.typeform.com/to/sWllcFNT";
const REQUEST_FORM_URL = "https://yodapitch.com/";

const TWITTER_URL = "https://twitter.com/sociallinetwork";
const FB_URL = "https://www.facebook.com/sociallinetwork/";
const INSTA_URL = "https://www.instagram.com/sociallinetwork/";
const LI_URL = "https://www.linkedin.com/company/social-li/";
const YT_URL = "https://www.youtube.com/channel/UC088EV4T9fbRoGeaM-owD_g";

export {
  REFORM_URL,
  REQUEST_FORM_URL,
  FB_URL,
  TWITTER_URL,
  INSTA_URL,
  LI_URL,
  YT_URL,
};
