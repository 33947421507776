import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Provider } from "react-redux";
import { ThemeProvider } from "styled-components";
import ReactGA from "react-ga";
import { HomePage } from "./container/HomePage";
import LandingPageInvest from "./container/LandingPageInvest/LandingPage";
import configureStore from "./redux/store";
import { AboutPage } from "./container/AboutPage";
import { TeamPage } from "./container/TeamPage";

import "./static/css/static.css";
import { theme } from "./utils/themeVariables";

import "./static/css/static.css";
import { ScrollToTop } from "./components/ScrollTop";
const TRACKING_ID = "UA-257963685-1"; // OUR_TRACKING_ID
ReactGA.initialize(TRACKING_ID);

// TODO:
// Add Google analytics
// Add description for metaworlds

const App = () => {
  return (
    <div className="app">
      <Provider store={configureStore}>
        <ThemeProvider theme={{ ...theme }}>
          <BrowserRouter>
            <ScrollToTop />
            <Routes>
              <Route path="/" element={<HomePage />} />
              <Route path="/invest" element={<LandingPageInvest />} />
              <Route path="/about" element={<AboutPage />} />
              <Route path="team" element={<TeamPage />} />
            </Routes>
          </BrowserRouter>
        </ThemeProvider>
      </Provider>
    </div>
  );
};
export default App;
