import React from "react";
import PropTypes from "prop-types";
import { useMediaQuery } from "react-responsive";
import { SentModal } from "./style";
import {
  BasicFormWrapper,
  RequestSentText,
  RequestInviteTitle,
} from "../styled";

const RequestSentModal = ({ visible, onCancel }) => {
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 720px)" });

  const handleCancel = () => {
    onCancel();
  };

  return (
    <SentModal
      width={isTabletOrMobile ? "95%" : "35%"}
      visible={visible}
      footer={null}
      onCancel={handleCancel}
    >
      <BasicFormWrapper>
        <RequestInviteTitle>Request sent</RequestInviteTitle>
        <div style={{ marginTop: "12px" }}>
          <RequestSentText>
            Thanks for your interest in Socialli.
          </RequestSentText>
          <RequestSentText>
            You will hear from us in next 24 hours.
          </RequestSentText>
        </div>
      </BasicFormWrapper>
    </SentModal>
  );
};

RequestSentModal.propTypes = {
  visible: PropTypes.bool,
  onCancel: PropTypes.func,
};

export default RequestSentModal;
