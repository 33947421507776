import React from "react";
import { ProfileComponentWrap, TeamGridWrap } from "./style";
import TwitterImg from "../../../../static/assets/TeamPageAssets/twitter 1.png";
import LIImg from "../../../../static/assets/TeamPageAssets/linkedin 1.png";

import AmitImg from "../../../../static/assets/TeamPageAssets/amit.png";
import VarunImg from "../../../../static/assets/TeamPageAssets/varun.png";
import LokieImg from "../../../../static/assets/TeamPageAssets/loakesh.png";
import ShreyaImg from "../../../../static/assets/TeamPageAssets/shreya.png";
import AsitImg from "../../../../static/assets/TeamPageAssets/asit.png";
import AnanyaImg from "../../../../static/assets/TeamPageAssets/ananya.png";
import TejaImg from "../../../../static/assets/TeamPageAssets/tejas.png";
import RoshitImg from "../../../../static/assets/TeamPageAssets/roshit.png";
import { DescLight } from "../../../../components/Headings";

const TeamData = [
  {
    name: "Amit",
    img: AmitImg,
    field_desg: "CEO",
    tUrl: "https://twitter.com/abeoye",
    lUrl: "https://in.linkedin.com/in/startupamit",
  },
  {
    name: "Roshit",
    img: RoshitImg,
    field_desg: "CTO",
    tUrl: "https://twitter.com/roshit_ko",
    lUrl: "https://in.linkedin.com/in/roshit",
  },
  {
    name: "Varun",
    img: VarunImg,
    field_desg: "Tech",
    tUrl: "https://twitter.com/varun_gyan",
    lUrl: "https://www.linkedin.com/in/varunsays/",
  },
  {
    name: "Loakesh",
    img: LokieImg,
    field_desg: "Tech",
    tUrl: "",
    lUrl: "https://www.linkedin.com/in/loakesh-indiran-8a2282140/",
  },
  {
    name: "Tejas",
    img: TejaImg,
    field_desg: "Tech",
    tUrl: "",
    lUrl: "https://in.linkedin.com/in/tejas-vijay-1430a3190",
  },
  {
    name: "Ananya",
    img: AnanyaImg,
    field_desg: "Marketing",
    tUrl: "",
    lUrl: "https://in.linkedin.com/in/ananya-mohapatra-a1a16b25",
  },
  {
    name: "Asit",
    img: AsitImg,
    field_desg: "Community",
    tUrl: "https://twitter.com/AsitDatar",
    lUrl: "https://www.linkedin.com/in/asit-datar/",
  },
  {
    name: "Shreya",
    img: ShreyaImg,
    field_desg: "Partnerships",
    tUrl: "https://twitter.com/shreyabadonia",
    lUrl: "https://www.linkedin.com/in/shreyabadonia/",
  },
];

export const TeamGrid = () => {
  const ProfileComponent = (data) => {
    const { img, name, field_desg, tUrl, lUrl } = data.data;
    return (
      <ProfileComponentWrap>
        <img src={img} alt="profile-img" className={`${name} profile-img`} />
        <section className=" profile-texts">
          <span className="name">{name} </span>
          <div className="desg">
            <DescLight>{field_desg}</DescLight>
          </div>
        </section>

        <section>
          {tUrl && (
            <a href={tUrl} target="_black">
              <img src={TwitterImg} alt="twitter-img" className="icons" />
            </a>
          )}
          <a href={lUrl} target="_black">
            <img src={LIImg} alt="Li-img" className="icons" />
          </a>
        </section>
      </ProfileComponentWrap>
    );
  };
  return (
    <TeamGridWrap>
      <div className="title">MEET THE MASTERMINDS</div>
      <div className="first-grid">
        <ProfileComponent data={TeamData[0]} />
        <ProfileComponent data={TeamData[1]} />;
      </div>
    </TeamGridWrap>
  );
};
