import styled from "styled-components";
const ButtonStyle = styled.button`
  background: linear-gradient(270deg, #27d5e0 0%, #60ff38 100%);
  box-shadow: 0px 15px 25px rgba(34, 167, 93, 0.25);
  border-radius: 52px;
  padding: 1.3vw 4vw;
  border: 0;
  cursor: pointer;
  font-family: Sen;
  font-weight: 700;
  font-size: 1.2vw;
  line-height: 21px;
  text-align: center;
  color: ${({ theme }) => theme["btn-color"]};
  @media only screen and (max-width: 768px) {
    font-size: 18px;
    line-height: 21px;
    padding: 2vh 4vh;
  }
`;
export { ButtonStyle };
