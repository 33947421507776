import React, { useEffect } from "react";
import Slider from "react-slick";
import {
  MdOutlineArrowForwardIos,
  MdOutlineArrowBackIos,
} from "react-icons/md";
import { useInView } from "react-intersection-observer";
import { motion, useAnimation } from "framer-motion";
import { WorldsWrapper } from "./style";
import { SubTitle, Title } from "../../components/Headings";
import { Card } from "../../components/Card";
import { worlds } from "../../utils/worlds";
import { fadeIn, fadeInShow } from "../../utils/motion";

function LeftNavButton(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className="slick-arrow left"
      style={{ ...style, display: "block", color: "white" }}
      onClick={onClick}
    >
      <MdOutlineArrowBackIos className="left" size={30} />
    </div>
  );
}

function RightNavButton(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className="slick-arrow right"
      style={{ ...style, color: "white" }}
      onClick={onClick}
    >
      <MdOutlineArrowForwardIos className="right" size={30} />
    </div>
  );
}

export const Worlds = () => {
  const [ref, inView] = useInView({
    threshold: 0.2,
  });
  const animation = useAnimation();

  useEffect(() => {
    if (inView) {
      animation.start(fadeInShow("down", "spring", 0.5, 1));
    }

    if (!inView) {
      animation.start({
        y: -50,
        opacity: 0,
      });
    }
  }, [inView]);

  const settings = {
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 2,
    initialSlide: 0,
    arrows: true,
    prevArrow: <LeftNavButton />,
    nextArrow: <RightNavButton />,
    responsive: [
      {
        breakpoint: 1500,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 2,
          // infinite: true,
        },
      },
      {
        breakpoint: 1300,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 1100,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 850,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
    ],
  };

  return (
    <WorldsWrapper>
      <motion.div ref={ref} animate={animation} className="world-header">
        <Title>Navigating and Interacting in the Metaverse</Title>
        <div className="tag-line">
          <SubTitle>Explore our Metaworlds</SubTitle>
        </div>
      </motion.div>
      <div className="show-worlds">
        <span className="view-btn">View All</span>
        <span>
          {" "}
          <MdOutlineArrowForwardIos />
        </span>
      </div>
      <div className="slider">
        <Slider {...settings}>
          {worlds.map((world, index) => (
            <Card
              img={world.img}
              title={world.title}
              desc={world.description}
              key={world.title}
              link={world.link}
              index={index}
            />
          ))}
        </Slider>
      </div>
      <div className="gradient"></div>
    </WorldsWrapper>
  );
};
