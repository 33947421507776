import React from "react";
import PropTypes from "prop-types";
import { useMediaQuery } from "react-responsive";
import { Col, Form, Input, Row, Select } from "antd";
import { useDispatch } from "react-redux";
import { RequestModal } from "./style";
import {
  BasicFormWrapper,
  RequestInviteText,
  RequestInviteButton,
  RequestInviteButtonText,
  RequestInviteTitle,
} from "../styled";
import { requestInvite } from "../../redux/modals/actionCreator";

const { Option } = Select;

const RequestInviteModal = ({ visible, onCancel, onSent }) => {
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 720px)" });
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const onFinish = (values) => {
    const { name, email, investmentBucket, investorType } = values;
    const postData = {
      name,
      email,
      investmentBucket,
      investorType,
    };
    dispatch(requestInvite(postData, onSent));
  };

  const handleCancel = () => {
    onCancel();
  };

  return (
    <RequestModal
      width={isTabletOrMobile ? "95%" : "35%"}
      visible={visible}
      footer={null}
      onCancel={handleCancel}
    >
      <BasicFormWrapper>
        <RequestInviteTitle>Request invite</RequestInviteTitle>
        <RequestInviteText>
          Get exclusive access to our novel video pitch bot and understand what
          makes Socialli special.
        </RequestInviteText>
        <Form form={form} name="sendInviteModal" onFinish={onFinish}>
          <Row gutter={20} style={{ display: "inherit" }}>
            <Col lg={24}>
              <Form.Item
                style={{ marginBottom: "30px" }}
                rules={[{ required: true, message: "Please input full name" }]}
                name="name"
                label="Name"
              >
                <Input placeholder="Enter full name" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={20} style={{ display: "inherit" }}>
            <Col lg={24}>
              <Form.Item
                style={{ marginBottom: "30px", width: "100%" }}
                rules={[
                  {
                    required: true,
                    message: "Please input your email",
                    type: "email",
                  },
                ]}
                name="email"
                label="Email"
              >
                <Input placeholder="Enter email" />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={20} style={{ display: "inherit" }}>
            <Col lg={24}>
              <Form.Item
                style={{ marginBottom: "30px" }}
                rules={[
                  {
                    required: true,
                    message: "Please select the investor type",
                  },
                ]}
                name="investorType"
                label="Investor Type"
                initialValue=""
              >
                <Select>
                  <Option value="">Select</Option>
                  <Option value="ANGEL_INVESTOR">Angel Investor</Option>
                  <Option value="INSTITUTIONAL_INVESTOR">
                    Institutional Investor
                  </Option>
                  <Option value="RETAIL_INVESTOR">Retail Investor</Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={20} style={{ display: "inherit" }}>
            <Col lg={24}>
              <Form.Item
                style={{ marginBottom: "30px" }}
                rules={[
                  {
                    required: true,
                    message: "Please select the investor bucket",
                  },
                ]}
                name="investmentBucket"
                label="Investor Bucket"
                initialValue=""
              >
                <Select>
                  <Option value="">Select</Option>
                  <Option value="$ 0-5000">$ 0-5000</Option>
                  <Option value="$ 5-25k">$ 5-25k</Option>
                  <Option value="$ 25-100k">$ 25-100k</Option>
                  <Option value="$ 100k-500k">$ 100k-500k</Option>
                  <Option value="$ 500k - 2M">$ 500k - 2M</Option>
                  <Option value="$2M+">$2M+</Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={20} style={{ display: "inherit" }}>
            <Col lg={24}>
              <RequestInviteButton>
                <RequestInviteButtonText
                  form="sendInviteModal"
                  key="submit"
                  htmlType="submit"
                >
                  Request Now
                </RequestInviteButtonText>
              </RequestInviteButton>
            </Col>
          </Row>
        </Form>
      </BasicFormWrapper>
    </RequestModal>
  );
};

RequestInviteModal.propTypes = {
  visible: PropTypes.bool,
  onCancel: PropTypes.func,
  onSent: PropTypes.func,
};

export default RequestInviteModal;
