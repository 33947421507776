import styled from "styled-components";
import { BannerWrapper, HeroWrapper } from "../../../Banner/style";

const HeroWrapperAbout = styled(HeroWrapper)`
  .lines {
    padding-bottom: 0;
  }
  @media only screen and (max-width: 768px) {
    .lines {
      padding-bottom: 5%;
    }
  }
`;

const BannerAboutPageWrap = styled(BannerWrapper)`
  padding: 0px 6% 18% 8%;
  section {
    .hero_data {
      width: 60%;
      padding-top: 6%;
      .hero-title {
        padding-bottom: 2%;
      }
    }
    .headline {
      font-size: 3.5vw;
      line-height: 3.6vw;
    }
    .hero_img {
      img {
        width: 37vw;
        margin-top: auto;
      }
    }
  }
  @media only screen and (max-width: 768px) {
    padding: 0 8% 5rem 8%;
    height: auto;
    header {
      img {
        width: 40%;
      }
    }
    section {
      flex-direction: column;
      .hero_data {
        width: auto;
        padding-top: 16%;
        .hero-title {
          padding-bottom: 4%;
        }
      }
      .headline {
        font-size: 41px;
        line-height: 100%;
        letter-spacing: -0.03em;
        margin-top: 16%;
      }
      .desc {
        font-size: 16px;
        line-height: 24px;
        margin: 8% 0;
      }
      .hero_img {
        img {
          width: 100%;
          padding-top: 2%;
          margin-top: auto;
        }
      }
    }
  }
`;

const AboutPageCTAWrap = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: 8%;
  .cta_data {
    width: 46%;
    .title {
      font-family: Sen;
      font-style: normal;
      font-weight: 700;
      font-size: 1.4vw;
      line-height: 1.8vw;
      letter-spacing: -0.03em;
      color: #ffffff;
      padding-bottom: 2%;
    }
    .desc {
      display: block;
      font-family: Sen;
      color: #aab2db;
      p {
        padding-bottom: 2%;
      }
    }
    .interested {
      font-family: Sen;
      font-style: normal;
      font-weight: 700;
      line-height: 24px;
      font-size: 1.3vw;
      color: #43ffdd;
      padding-top: 1.5rem;
    }
  }
  .cta_img {
    img {
      width: 42vw;
    }
  }

  @media only screen and (max-width: 768px) {
    height: auto;
    flex-direction: column-reverse;
    .cta_data {
      width: 100%;
      .title {
        font-size: 22px;
        line-height: 120%;
        padding-bottom: 2%;
      }
      .desc {
        display: block;
        font-family: Sen;
        color: #aab2db;
        p {
          padding-bottom: 2%;
        }
      }
      .interested {
        font-family: Sen;
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 19px;
        color: #43ffdd;
        padding-top: 0.8rem;
      }
    }
    .cta_img {
      padding-top: 2rem;
      img {
        width: 100%;
      }
    }
  }
`;

export { HeroWrapperAbout, BannerAboutPageWrap, AboutPageCTAWrap };
