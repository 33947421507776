import React from "react";
import { EaryAccessCard, PartnersWrap } from "./style";
import { Button } from "../../components/Button/Button";
import { TitleBlue } from "../../components/Headings";
import EventSliders from "../../components/EventSliders";
import { REFORM_URL } from "../../utils/constant";

export const Partners = () => {
  return (
    <PartnersWrap>
      <EaryAccessCard>
        <div className="pitch-line">
          Apply for our upcoming cohorts to jumpstart your journey to the
          Metaverse
        </div>
        <a href={REFORM_URL} className="join-now" target="_blank">
          <Button>Get Early Access &nbsp; &nbsp;></Button>
        </a>
      </EaryAccessCard>
      <section>
        <div className="title">
          <TitleBlue>Partnerships / Early Interest </TitleBlue>
        </div>
        <div className="title-slider">
          <EventSliders />
        </div>
      </section>
    </PartnersWrap>
  );
};
