import React from "react";
import EventComponent from "./overview/EventComonent";
import { AllEventsWrapper } from "./style";

import polkadotImg from "../../static/assets/polkadot-india.png";
import theschoolofmetaImg from "../../static/assets/theschoolofmeta.png";
import metafactoryImg from "../../static/assets/themetafactory.png";
import metameetsImg from "../../static/assets/metameets.png";
import metalistImg from "../../static/assets/metalist.png";
import headstartImg from "../../static/assets/headstart-network.png";
import metaportImg from "../../static/assets/metaport.png";
import metamatterImg from "../../static/assets/metamatter.png";
import metaestateImg from "../../static/assets/metaestate.png";
import foundersImg from "../../static/assets/1000-founders.png";
import hack2skillImg from "../../static/assets/hack2skill.png";
import bindiaImg from "../../static/assets/blockchain-india.png";
import borntodieImg from "../../static/assets/borntodie.png";

const EventSliders = () => {
  const ParterData = [
    { name: "polkadot", img: polkadotImg },
    { name: "theschoolofmeta", img: theschoolofmetaImg },
    { name: "metafactory", img: metafactoryImg },
    { name: "metameets", img: metameetsImg },
    { name: "metalist", img: metalistImg },
    { name: "headstart", img: headstartImg },
    { name: "metaport", img: metaportImg },
    { name: "metamatter", img: metamatterImg },
    { name: "metaestate", img: metaestateImg },
    { name: "founders", img: foundersImg },
    { name: "hack2skill", img: hack2skillImg },
    { name: "bindia", img: bindiaImg },
    { name: "borntodie", img: borntodieImg },
  ];

  return (
    <AllEventsWrapper>
      <EventComponent events={ParterData} isLast={false} />
    </AllEventsWrapper>
  );
};

export default EventSliders;
