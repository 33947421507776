import React from "react";
import { PartnersWrapTeamPage } from "./style";
import EventSliders from "../../../../components/EventSliders";
import { TitleBlue } from "../../../../components/Headings";
import Awards from "../../../AboutPage/overview/Awards";

export const PartnersAndAwards = () => {
  return (
    <>
      <PartnersWrapTeamPage>
        <section>
          <div className="title">
            <TitleBlue>Partnerships / Early Interest </TitleBlue>
          </div>
          <div className="title-slider">
            <EventSliders />
          </div>
        </section>
      </PartnersWrapTeamPage>
      <Awards />
    </>
  );
};
