import React from "react";
import { Link } from "react-router-dom";

import {
  AboutPageCTAWrap,
  BannerAboutPageWrap,
  HeroWrapperAbout,
} from "./style";
import { Button } from "../../../../components/Button/Button";
import {
  AboutPageText,
  DescLight,
  Title,
} from "../../../../components/Headings";
import SocialliLogo from "../../../../static/assets/social.li logo.png";
import HeroImg1 from "../../../../static/assets/AboutPageAssets/socialli-layer1-blockchain.png";
import HeroImg2 from "../../../../static/assets/AboutPageAssets/socialli-social-token-land-parcel.png";

import LinesBg from "../../../../static/assets/wave.png";
import { REFORM_URL } from "../../../../utils/constant";

export const BannerPageAbout = () => {
  return (
    <>
      <HeroWrapperAbout>
        <BannerAboutPageWrap>
          <header>
            <Link to="/">
              <img src={SocialliLogo} />
            </Link>
          </header>
          <section>
            <div className="hero_data">
              <div className="hero-title">
                <Title>ABOUT US</Title>
              </div>

              <span className="headline">
                <span className="colored">
                  Socialli is a layer 1 blockchain
                </span>
                <br /> for building interoperable web3 metaverses.
              </span>

              <div className="desc">
                <DescLight>
                  Metaverse is the future of the internet. Web 2 communities and
                  brands are going to make it to Web3 through the metaverse.
                </DescLight>
              </div>
              <a href={REFORM_URL} target="_blank">
                <Button>Join the tribe &nbsp; &nbsp;></Button>
              </a>
            </div>
            <div className="hero_img">
              <img src={HeroImg1} />
            </div>
          </section>

          <AboutPageCTAWrap>
            <div className="cta_img">
              <img src={HeroImg2} />
            </div>
            <div className="cta_data">
              <div className="title">
                Each metaverse has its own Social Token and NFTs with built-in
                DeFi/ GameFi capabilities.
              </div>
              <div className="desc">
                <p>
                  <AboutPageText>
                    These indie metaverses allow creators to create their own
                    metaverse economy, creating multiple revenue streams for the
                    creators. This land parcel can be developed, designed, and
                    altered based on the efficiency of builders. The whole
                    ecosystem is gamified for developers to make it fun and
                    coherent.
                  </AboutPageText>
                </p>
                <p>
                  <AboutPageText>
                    Once developed, the land parcels can be used in staking,
                    renting, and selling to other creators of the network and
                    brands and corporates entering the metaverse.
                  </AboutPageText>
                </p>
              </div>
              <div className="interested">
                <a href={REFORM_URL} target="_blank">
                  Take me to the land parcel map >
                </a>
              </div>
            </div>
          </AboutPageCTAWrap>
        </BannerAboutPageWrap>
        <img alt="lines" src={LinesBg} className="lines" />
      </HeroWrapperAbout>
    </>
  );
};
