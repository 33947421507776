import React from "react";
import Slider from "react-slick";
import { EcosSytemWrapper } from "./style";

import SchoolOfMetaDeck from "../../../../static/assets/AboutPageAssets/schoolofmeta.png";
import LogoSchoolOfMeta from "../../../../static/assets/theschoolofmeta.png";

import MetamatterDeck from "../../../../static/assets/AboutPageAssets/metamatter.png";
import LogoMetamatter from "../../../../static/assets/metamatter.png";
import MetaportDeck from "../../../../static/assets/AboutPageAssets/metaport.png";
import LogoMetaport from "../../../../static/assets/metaport.png";
import MetaEstateDeck from "../../../../static/assets/AboutPageAssets/metaestate.png";
import LogoMetaEstate from "../../../../static/assets/metaestate.png";
import MetaFactDeck from "../../../../static/assets/AboutPageAssets/themetafactory.png";
import LogoMetaFact from "../../../../static/assets/themetafactory.png";
import MetameetsDeck from "../../../../static/assets/AboutPageAssets/metameets.png";
import LogoMetameets from "../../../../static/assets/metameets.png";

import ArrowRight from "../../../../static/assets/AboutPageAssets/arrow_R.png";
import ArrowLeft from "../../../../static/assets/AboutPageAssets/arrow_L.png";

import {
  AboutPageHeadline,
  AboutPageText,
} from "../../../../components/Headings";
import { useIsTab } from "../../../../utils/utils";

export const EcoSystem = () => {
  const isTab = useIsTab();

  const RightArrow = (props) => {
    const { onClick } = props;
    return <img className="right-icon" onClick={onClick} src={ArrowRight} />;
  };
  const LeftArrow = (props) => {
    const { onClick } = props;
    return <img className="left-icon" onClick={onClick} src={ArrowLeft} />;
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: !isTab,
    nextArrow: <RightArrow />,
    prevArrow: <LeftArrow />,
  };

  const SliderData = [
    {
      title: "TheSchoolofMeta",
      desc:
        "Web3 education community to hangout, learn, ask, share and build cool stuff to thrive in the Metaverse!",
      img: SchoolOfMetaDeck,
      logo: LogoSchoolOfMeta,
    },
    {
      title: "Metamatter",
      desc:
        "Metamatter is an NFT marketplace made for brands that help them create, launch, and promote NFTs across interoperable metaverses. ",
      img: MetamatterDeck,
      logo: LogoMetamatter,
    },
    {
      title: "Metameets",
      desc:
        "Metameets is the one-stop destination to host parties, conferences, team-building events, corporate celebrations, and much more.",
      img: MetameetsDeck,
      logo: LogoMetameets,
    },
    {
      title: "Metaport",
      desc:
        "Metaport is an intermetaverse portal that allows users to move from one metaverse to the other quickly. ",
      img: MetaportDeck,
      logo: LogoMetaport,
    },
    {
      title: "MetaEstate",
      desc:
        "Meta Estate provides virtual real estate services for brands, corporates and family offices looking at procuring virtual real estate. ",
      img: MetaEstateDeck,
      logo: LogoMetaEstate,
    },
    {
      title: "TheMetaverseFactory",
      desc:
        "The Metaverse Factory is an incubator for Metaverse first projects. Our team is an advisor and guides the incubatee startups with tech, business and strategy. ",
      img: MetaFactDeck,
      logo: LogoMetaFact,
    },
  ];

  return (
    <EcosSytemWrapper>
      <div className="head">
        <AboutPageHeadline>The Social.li Ecosystem</AboutPageHeadline>{" "}
      </div>
      <div className="main-slider">
        <Slider {...settings}>
          {SliderData.map((item) => {
            const { title, desc, img, logo } = item;
            return (
              <div>
                <div className="slider">
                  <img className="slide-img" src={img} alt="SchoolOfMetaDeck" />
                  <section>
                    <img className="logo" src={logo} alt="LogoSchoolOfMeta" />
                    <div className="title">{title}</div>
                    <p className="desc">
                      <AboutPageText>{desc}</AboutPageText>
                    </p>
                  </section>
                </div>
              </div>
            );
          })}{" "}
          {/* <div>
            <div className="slider">
              <img
                className="slide-img"
                src={SchoolOfMetaDeck}
                alt="SchoolOfMetaDeck"
              />
              <section>
                <img
                  className="logo"
                  src={LogoSchoolOfMeta}
                  alt="LogoSchoolOfMeta"
                />
                <div className="title">TheSchoolofMeta</div>
                <p className="desc">
                  <AboutPageText>
                    Web3 education community to hangout, learn, ask, share and
                    build cool stuff to thrive in the Metaverse!
                  </AboutPageText>
                </p>
              </section>
            </div>{" "}
          </div>
          <div>
            <div className="slider">
              <img
                className="slide-img"
                src={SchoolOfMetaDeck}
                alt="SchoolOfMetaDeck"
              />
              <section>
                <img
                  className="logo"
                  src={LogoSchoolOfMeta}
                  alt="LogoSchoolOfMeta"
                />
                <div className="title">TheSchoolofMeta</div>
                <p className="desc">
                  <AboutPageText>
                    Web3 education community to hangout, learn, ask, share and
                    build cool stuff to thrive in the Metaverse!
                  </AboutPageText>
                </p>
              </section>
            </div>
          </div> */}
        </Slider>
      </div>
    </EcosSytemWrapper>
  );
};
