import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./LandingPage.css";
import {
  PageContainer,
  Header,
  Body,
  HeaderButton,
  HeaderButtonText,
  FooterText,
  BodyContent,
  BodyContentBigText,
  BodyContentSmallText,
  BodyContentLogo,
  BodyButton,
  Footer,
  BodyButtonText,
} from "./styledComponents";
import Logo from "../../static/assets/social.li logo.png";
import BodyLogo from "../../static/assets/AboutPageAssets/socialli-layer1-blockchain.png";

import RequestInviteModal from "../Modals/RequestInviteModal";
import RequestSentModal from "../Modals/RequestSentModal";
// import { Footer } from '../SocialliLandingPage/components/Footer';

const LandingPage = () => {
  const [state, setState] = useState({
    requestInviteModal: false,
    requestSentModal: false,
  });

  const handleOpenSent = () => {
    setState({
      ...state,
      requestInviteModal: false,
      requestSentModal: true,
    });
  };

  const handleCancelModal = () => {
    setState({
      ...state,
      requestInviteModal: false,
      requestSentModal: false,
    });
  };

  const { requestInviteModal, requestSentModal } = state;

  return (
    <>
      <PageContainer>
        <Header>
          <Link to="/">
            <img src={Logo} alt="logo" height={50} />
          </Link>
          <HeaderButton
            onClick={() =>
              setState({
                ...state,
                requestInviteModal: true,
              })
            }
          >
            <HeaderButtonText>Request Invite</HeaderButtonText>
          </HeaderButton>
        </Header>

        <Body>
          <BodyContent>
            <BodyContentBigText>
              Build Web 3 interoperable metaverses with Socialli
            </BodyContentBigText>
            <BodyContentSmallText>
              This website hosts our video pitch bot. It&apos;s invite only for
              now.
            </BodyContentSmallText>

            <BodyButton
              onClick={() =>
                setState({
                  ...state,
                  requestInviteModal: true,
                })
              }
            >
              <BodyButtonText>Request Invite</BodyButtonText>
            </BodyButton>
          </BodyContent>

          <BodyContentLogo>
            <img
              src={BodyLogo}
              alt="yoda-logo"
              className="body-logo"
              height="50px"
            />
          </BodyContentLogo>
        </Body>

        <Footer>
          <FooterText>&copy; 2022 Social Labz. all rights reserved.</FooterText>
        </Footer>

        {requestInviteModal && (
          <RequestInviteModal
            onSent={handleOpenSent}
            onCancel={handleCancelModal}
            visible={requestInviteModal}
          />
        )}

        {requestSentModal && (
          <RequestSentModal
            onCancel={handleCancelModal}
            visible={requestSentModal}
          />
        )}
      </PageContainer>
    </>
  );
};

export default LandingPage;
