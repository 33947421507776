import React, { useEffect } from "react";
import { useInView } from "react-intersection-observer";
import { MdOutlineArrowForwardIos } from "react-icons/md";
import Tilt from "react-parallax-tilt";
import { motion, useAnimation } from "framer-motion";
import { CardWrapper } from "./style";
import { fadeIn, slideIn } from "../../utils/motion";

export const Card = ({ img, title, desc, index, link }) => {
  const titleLower = title.toLowerCase();
  const [ref, inView] = useInView({
    threshold: 0.2,
  });
  const animation = useAnimation();

  useEffect(() => {
    if (inView) {
      animation.start({
        x: 0,
        opacity: 1,
        transition: {
          type: "spring",
          delay: index * 0.5,
          duration: 1,
          bounce: 0.5,
          ease: "easeOut",
        },
      });
    }

    if (!inView) {
      animation.start({
        x: -50,
        opacity: 0,
      });
    }
  }, [inView]);

  return (
    <Tilt>
      <motion.div ref={ref} animate={animation}>
        <CardWrapper>
          <a
            // href={`https://${titleLower}.li/`}
            href={link}
            target="_blank"
            rel="noopener noreferrer"
          >
            <div className="card-img">
              <img src={img} className="world-img" />
              <div className="card-header">
                <h3 className="card-title">{title}</h3>
                {/* <img src={logo} alt="" className="logo" /> */}
                <span>
                  <MdOutlineArrowForwardIos size={24} />
                </span>
              </div>
              <div className="card-desc">{desc}</div>
            </div>
            <div className="overlay"></div>
          </a>
        </CardWrapper>
      </motion.div>
    </Tilt>
  );
};
