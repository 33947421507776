import React from "react";
import { TimelineDiv, TimelineWrapper } from "./style";
import DotImg from "../../static/assets/roadmap circle (1).png";
import { AboutPageHeadline } from "../../components/Headings";

const Timeline = () => {
  return (
    <TimelineWrapper>
      <div className="roadmap-title">
        <AboutPageHeadline>Roadmap</AboutPageHeadline>
      </div>
      <TimelineDiv>
        <ul id="timeline">
          <div className="line"></div>
          <li className="event down">
            <div className="content">
              <div className="avatar">
                <div className="time">Oct 2022</div>
                <div className="text">
                  Genesis NFT Collection Reveal,Community Launch, Testnet Launch
                </div>
              </div>
            </div>

            <div className="dot">
              <img className="circle" src={DotImg} />
            </div>
          </li>
          <li className="event up">
            <div className="content">
              <div className="avatar">
                <div className="time">Dec 2022</div>
                <div className="text">
                  Genesis NFT Collection Launch, Metahome Reveal, Land Parcels
                  Minting
                </div>
              </div>
            </div>
            <div className="dot">
              <img className="circle" src={DotImg} />
            </div>
          </li>
          <li className="event down">
            <div className="content">
              <div className="avatar">
                <div className="time">Feb 2023</div>
                <div className="text">
                  Level 2 NFT Collection Launch, Mainnet Launch, Unity based
                  Metahomes
                </div>
              </div>
            </div>
            <div className="dot">
              <img className="circle" src={DotImg} />
            </div>
          </li>
          <li className="event up">
            <div className="content">
              <div className="avatar">
                <div className="time">Apr 2023</div>
                <div className="text">
                  Multiplayer Metaverse Launch, Onboarding Communities
                </div>
              </div>
            </div>
            <div className="dot">
              <img className="circle" src={DotImg} />
            </div>
          </li>
          <li className="event down">
            <div className="content">
              <div className="avatar">
                <div className="time">Jun 2023</div>
                <div className="text">
                  Onboarding Brands, Celebs, Metaverse service providers
                </div>
              </div>
            </div>
            <div className="dot">
              <img className="circle" src={DotImg} />
            </div>
          </li>
        </ul>
      </TimelineDiv>
    </TimelineWrapper>
  );
};

export default Timeline;
