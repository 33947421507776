import MetaSpace from "../static/assets/WorldsAssets/MetaSpace.png";
import MetaGallery from "../static/assets/WorldsAssets/MetaGallery.png";
import MetaFantasy from "../static/assets/WorldsAssets/MetaFantasy.png";
import MetaCity from "../static/assets/WorldsAssets/MetaCity.png";
import MetaHome from "../static/assets/WorldsAssets/MetaHome.png";

export const worlds = [
  {
    img: MetaHome,
    title: "MetaHome",
    description:
      "Own a home in the Metaverse and host events, flaunt NFT’s and invite friends to your home",
    link: "https://spaces.social.li/metahome/",
  },
  {
    img: MetaSpace,
    title: "MetaSpace",
    description:
      "MetaSpace is another Multiverse that is designed as a world where players can explore space.",
    link: "https://spaces.social.li/metaspace/",
  },
  {
    img: MetaGallery,
    title: "MetaGallery",
    description:
      "MetaGallery is another Multiverse that is designed as a world where players can host their exclusive NFT Art.",
    link: "https://spaces.social.li/metagallery/",
  },
  {
    img: MetaFantasy,
    title: "MetaFantasy",
    description:
      "MetaFantasy, is a metaverse world inspired by fantasy, magic and mystery.",
    link: "https://spaces.social.li/metafantasy/",
  },
  {
    img: MetaCity,
    title: "MetaCity",
    description:
      "MetaCity is designed as a world where players can explore the city lifestyle. ",
    link: "https://spaces.social.li/metacity/",
  },
];
