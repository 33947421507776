import styled from "styled-components";
import LineImg from "../../static/assets/roadmap-line (1).png";

export const TimelineWrapper = styled.div`
  background: linear-gradient(158.19deg, #b93f71 0%, #5c2579 98.5%);
  padding: 6% 0 14% 0;
  .roadmap-title {
    display: flex;
    justify-content: center;
  }

  @media only screen and (max-width: 768px) {
    padding: 6% 0 14rem 0;
  }
`;

export const TimelineDiv = styled.div`
  display: flex;
  justify-content: center;
  body {
    margin: 0;
    padding: 0 20px;
    color: #f98262;
    background: #fffff0;
  }

  #timeline {
    list-style: none;
    height: 250px;
    margin: 250px 0 0;

    .line {
      background: url(${LineImg});
      height: 3px;

      background-size: contain;
      display: table-caption;
      margin-left: -9rem;
      margin-right: -6rem;
    }
    li {
      display: table-cell;
      position: relative;
      min-width: 180px;
    }
  }
  .circle {
    top: -28px;
    left: 34px;
    right: 0;
    width: 3rem;
    border-radius: 50%;
    display: block;
    position: absolute;
  }

  .content {
    text-align: center;
    position: relative;
  }
  .avatar {
    min-height: 53px;
    display: block;
    position: absolute;
    margin: 0 auto;
    left: 0;
    right: 0;
    text-align: center;
    line-height: 30px;
    background: linear-gradient(
      93.98deg,
      rgba(67, 223, 246, 0.2) 0.31%,
      rgba(255, 85, 248, 0.2) 100.27%
    );
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;

    .text {
      ont-weight: 400;
      font-size: 14.1px;
      line-height: 18px;
      text-align: center;
      letter-spacing: 0.02em;
      color: #ffffff;
    }
  }
  .content:before {
    content: "";
    width: 50px;
    height: 65px;
    left: 50%;
    position: absolute;
  }
  li.down {
    .time {
      padding: 2px 22px;
      width: fit-content;
      background: linear-gradient(93.98deg, #f6b943 0.31%, #ff1cf7 100.27%);
      border-radius: 50px;
      text-align: center;
      position: absolute;
      left: 0;
      right: 0;
      top: -1rem;
      margin: auto;

      font-weight: 700;
      font-size: 18px;
      text-align: center;
      letter-spacing: -0.03em;
      color: #292942;
    }
    .content {
      margin-left: -4rem;
      &:before {
        border-left: 1px solid #fc5ead;
      }
    }
    .avatar {
      top: 65px;
      padding: 2rem 1.5rem 1.5rem 1.5rem;
    }
  }
  li.up {
    .time {
      padding: 2px 22px;
      width: fit-content;
      border-radius: 50px;
      background: linear-gradient(93.98deg, #f6b943 0.31%, #ff1cf7 100.27%);

      text-align: center;
      position: absolute;
      bottom: -1rem;
      left: 0;
      right: 0;
      margin: auto;

      font-weight: 700;
      font-size: 18px;
      text-align: center;
      letter-spacing: -0.03em;
      color: #292942;
    }
    .content {
      margin-top: -106px;
      margin-left: -4rem;

      &:before {
        top: 32px;
        border-left: 1px solid #fc5ead;
      }
    }
    .date {
      margin-top: 106px;
    }
    .avatar {
      margin-top: -85px;
      padding: 1.5rem 1.5rem 2rem 1.5rem;
    }
  }
  .rectangle {
    border: 1px solid #f98262;
    border-radius: 30px;
    height: 30px;
    width: 200px;
    top: -19px;
    position: relative;
    line-height: 30px;
    text-align: center;
  }

  @media only screen and (max-width: 1150px) {
    display: block;
    overflow-y: scroll;
    padding-left: 3rem;
    #timeline {
      .line {
        margin-left: -6rem;
        margin-right: -6rem;
      }
    }
  }
`;
