import styled from "styled-components";

export const ValuesWrapper = styled.div`
  padding: 6% 12% 12% 12%;
  background: ${({ theme }) => theme["light-blue-color"]};
  ul {
    list-style-type: none;
    .middle {
      margin: 5% 0;
      padding: 5% 0;
      border-bottom: 1px solid #5b3c9d;
      border-top: 1px solid #5b3c9d;
    }
    li {
      display: flex;
      .title {
        flex: 1;
        padding-left: 5%;
        font-size: 52px;
        line-height: 120%;
        display: flex;
        align-items: center;
        letter-spacing: -0.03em;
      }
      .value {
        color: #ed55a7;
      }
      .vision {
        color: #df5050;
      }
      .mission {
        color: #ed8355;
      }
      .desc {
        flex: 2;
        padding-right: 5%;
      }
    }
  }
  @media only screen and (max-width: 768px) {
    padding: 6% 8% 8rem 8%;
    ul {
      margin: 0;
      padding: 0;
      .middle {
        margin: 2rem 0;
        padding: 2rem 0;
      }
      li {
        flex-direction: column;
        .title {
          padding-left: 0;
          padding-bottom: 1rem;
          font-size: 32px;
          line-height: 120%;
        }
      }
    }
  }
`;
