import React from "react";
import { Link } from "react-router-dom";
import {
  FooterText,
  FooterTextInvest,
  FooterTitle,
  FooterWrapper,
} from "./style";

import SocialLogo from "../../static/assets/social.li logo.png";
import FbLogo from "../../static/assets/fb.png";
import InstaLogo from "../../static/assets/insta.png";
import YTLogo from "../../static/assets/utube.png";
import TwitterLogo from "../../static/assets/twitter.png";
import LiLogo from "../../static/assets/linked.png";
import {
  FB_URL,
  INSTA_URL,
  LI_URL,
  TWITTER_URL,
  YT_URL,
} from "../../utils/constant";
import { useIsTab } from "../../utils/utils";

export const Footer = () => {
  const isTab = useIsTab();
  const Socials = () => {
    return (
      <div className="socials">
        <div className="links">
          <div className="title">
            <FooterText>Follow us</FooterText>
          </div>
          <a href={FB_URL} target="_blank" rel="noreferrer">
            <img src={FbLogo} alt="fb" />
          </a>
          <a href={INSTA_URL} target="_blank" rel="noreferrer">
            <img src={InstaLogo} alt="insta" />
          </a>
          <a href={YT_URL} target="_blank" rel="noreferrer">
            <img src={YTLogo} alt="utube" />
          </a>
          <a href={TWITTER_URL} target="_blank" rel="noreferrer">
            <img src={TwitterLogo} alt="twitter" />
          </a>
          <a href={LI_URL} target="_blank" rel="noreferrer">
            <img src={LiLogo} alt="linkedin" />
          </a>
        </div>
        <div>
          <FooterText>©2022 Social Labz. All rights reserved</FooterText>
        </div>
      </div>
    );
  };
  return (
    <FooterWrapper>
      <section>
        <div className="img-wrapper">
          <Link to="/" target="_blank">
            <img src={SocialLogo} className="social-logo" alt="social-logo" />
          </Link>
        </div>
        {!isTab && <Socials />}
      </section>

      <section className="quick-links">
        <FooterTitle>Quick Links</FooterTitle>
        <ul>
          <li>
            <Link to="/about">
              <FooterText> About</FooterText>
            </Link>
          </li>
          <li>
            <Link to="/team">
              <FooterText> Team</FooterText>
            </Link>
          </li>
          <li>
            <a href="https://www.social.li/blog">
              <FooterText> Blog</FooterText>
            </a>
          </li>
        </ul>
      </section>
      <section className="invest-section">
        <div>
          <FooterTitle>Invest in Social.li</FooterTitle>
        </div>
        <div className="invest-text">
          <FooterTextInvest>
            Social.li is raising its seed round.
            <br /> Please fill the form below and our team will get in touch.
          </FooterTextInvest>
        </div>

        <Link to="/invest" target="_blank">
          <div className="footer-btn">Invest</div>
        </Link>
      </section>
      {isTab && <Socials />}
    </FooterWrapper>
  );
};
