import styled from "styled-components";

export const EcosSytemWrapper = styled.div`
  padding: 8% 12% 10% 12%;
  background: linear-gradient(158.19deg, #200064 0%, #100047 98.5%);
  .right-icon {
    padding-left: 1%;
    object-fit: contain;
    cursor: pointer;
  }
  .left-icon {
    padding-right: 1%;
    object-fit: contain;
    cursor: pointer;
  }
  .head {
    margin: auto;
    padding-bottom: 4%;
    display: flex;
    justify-content: center;
  }
  .main-slider {
    .slick-slider {
      display: flex;
      .slick-dots li button:before {
        color: gray;
        opacity: 1;
      }
      .slick-dots li.slick-active button:before {
        opacity: 1;
        color: white;
      }
    }
    margin: auto;
    border-radius: 10px;
    .slider {
      padding: 9% 6% 8% 6%;
      background: linear-gradient(
        93.98deg,
        rgba(67, 223, 246, 0.2) 0.31%,
        rgba(255, 85, 248, 0.2) 100.27%
      );
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      border-radius: 10px;
      display: flex;
      justify-content: space-between;

      .slide-img {
        width: 50%;
        object-fit: contain;
      }

      section {
        width: 38%;
        padding-right: 2%;
        .logo {
          padding: 6% 0 8% 0;
        }
        .title {
          font-family: Sen;
          font-style: normal;
          font-weight: 700;
          font-size: 1.4vw;
          line-height: 1.8vw;
          letter-spacing: -0.03em;
          color: #ffffff;
        }
      }
    }
  }
  @media only screen and (max-width: 768px) {
    padding: 4rem 5% 5rem 5%;
    .head {
      padding-bottom: 3rem;
    }
    .main-slider {
      .slider {
        flex-direction: column;
        .slide-img {
          width: 100%;
        }
        section {
          width: 100%;
          .title {
            font-size: 22px;
            line-height: 120%;
            padding-bottom: 2%;
          }
          .logo {
            padding: 3rem 0 8% 0;
          }
        }
      }
    }
  }
`;
