import React from "react";
import { ThemeProvider } from "styled-components";
import { Banner } from "../Banner";
import { Feature } from "../Feature";
import { Footer } from "../Footer";
import { Objective } from "../Objective/Objective";
import { Partners } from "../Partners";
import { Path } from "../Path";
import { ServiceTab } from "../ServiceTab";
import { Worlds } from "../Worlds";
import { theme } from "../../utils/themeVariables";

export const HomePage = () => {
  return (
    <ThemeProvider theme={{ ...theme }}>
      <Banner />
      <Path />
      <Objective />
      <Worlds />
      <ServiceTab />
      <Feature />
      <Partners />
      <Footer />
    </ThemeProvider>
  );
};
