import React, { useEffect } from "react";

import { useInView } from "react-intersection-observer";
import { motion, useAnimation } from "framer-motion";
import { CardWrapper, FeatureCardStyle, FeatureWrapper } from "./style";
import { SubTitle } from "../../components/Headings";
import { TitleWrapperYellow } from "../../components/Headings/style";

import PriceDisImg from "../../static/assets/icon 1-price.png";
import GovImg from "../../static/assets/icon-2-governance.png";
import PlayBookImg from "../../static/assets/icon-3-playbooks.png";
import DefiImg from "../../static/assets/icon-4-content.png";
import ContentImg from "../../static/assets/icon-4-defi.png";
import { fadeInShow } from "../../utils/motion";

export const Feature = () => {
  const [ref, inView] = useInView({
    threshold: 0.2,
  });
  const animation = useAnimation();

  useEffect(() => {
    if (inView) {
      animation.start(animation.start(fadeInShow("down", "spring", 0.5, 1)));
    }

    if (!inView) {
      animation.start({
        y: -50,
        opacity: 0,
      });
    }
  }, [inView]);

  const FeatData = [
    {
      title: "Teleportation:",
      img: PriceDisImg,
      desc:
        "Travel to multiple metaverses through our interoperability bridge ",
    },
    {
      title: "Governance: ",
      img: GovImg,
      desc: "Novel on-chain governance mechanisms, suitable for DAOs ",
    },
    {
      title: "Playbooks: ",
      img: PlayBookImg,
      desc:
        "For games, celebrities, creators, social networks, corporates and brands",
    },
    {
      title: "DeFi",
      img: ContentImg,
      desc: "On-chain exchange and native DeFi protocols",
    },
    {
      title: "Rewards: ",
      img: DefiImg,
      desc: "Earn $SOCIAL tokens or win new Land block for your MetaHome",
    },
  ];

  const FeatureCard = ({ img, title, desc }) => {
    return (
      <FeatureCardStyle>
        <img src={img} />
        <p className="title">{title}</p>
        <div className="desc">{desc}</div>
      </FeatureCardStyle>
    );
  };

  return (
    <>
      <FeatureWrapper>
        <motion.section ref={ref} animate={animation}>
          <div className="feat-title">
            <TitleWrapperYellow>LAUNCHING SOON - METAHOMES</TitleWrapperYellow>
          </div>
          <div className="feat-sub">
            <SubTitle>Your Own Home in the Multiverse</SubTitle>
          </div>
        </motion.section>
        <CardWrapper>
          {FeatData.map((item) => {
            const { img, title, desc } = item;
            return (
              <FeatureCard key={title} img={img} title={title} desc={desc} />
            );
          })}
        </CardWrapper>
      </FeatureWrapper>
    </>
  );
};
