import React from "react";
import { ValuesWrapper } from "./style";
import { AboutPageText } from "../../../../components/Headings";

export const ValuesVision = () => {
  return (
    <ValuesWrapper>
      <ul>
        <li>
          <div className="title value">Values</div>
          <div className="desc">
            <AboutPageText>
              Our core values of innovation and fun encourage us to build a
              decentralized and open infrastructure for the frontline creators
              of the metaverse. We’ve developed frameworks so we approach all
              aspects of our business by making this journey seamless and
              delightful for our users.
            </AboutPageText>
          </div>
        </li>
        <li className="middle">
          <div className="title vision">Vision</div>
          <div className="desc">
            <AboutPageText>
              We aspire to create a network that brings the metaverse
              infrastructure to the masses. We aim to provide everyone an
              opportunity to explore the potential of the metaverse economy. We
              strongly believe Social.li will become a leading player in the
              interoperability and metaverse portability world.
            </AboutPageText>
          </div>
        </li>
        <li>
          <div className="title mission">Mission</div>
          <div className="desc">
            <AboutPageText>
              Our mission is to help creators, communities, and enterprises
              seamlessly transition to Web 3 and own a piece of the metaverse.
              This robust ecosystem of metaverses will create a flourishing
              economy for the community to benefit from.
            </AboutPageText>
          </div>
        </li>
      </ul>
    </ValuesWrapper>
  );
};
