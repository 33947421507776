import React from "react";
import { BannerTeamPage } from "./overview/BannerTeamPage";
import { JoinUSCard } from "./overview/JoinUSCard";
import { TeamGrid } from "./overview/TeamsGrid";
import { PartnersAndAwards } from "./overview/PartnersAndAwards";
import { Footer } from "../Footer";

export const TeamPage = () => {
  return (
    <div>
      <BannerTeamPage />
      <TeamGrid />
      <JoinUSCard />
      <PartnersAndAwards />
      <Footer />
    </div>
  );
};
