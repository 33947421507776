const actions = {
  MODAL_REQUEST_BEGIN: 'MODAL_REQUEST_BEGIN',
  MODAL_REQUEST_SUCCESS: 'MODAL_REQUEST_SUCCESS',
  MODAL_REQUEST_ERR: 'MODAL_REQUEST_ERR',

  modalRequestBegin: () => {
    return {
      type: actions.MODAL_REQUEST_BEGIN,
    };
  },

  modalRequestSuccess: data => {
    return {
      type: actions.MODAL_REQUEST_SUCCESS,
      data,
    };
  },

  modalRequestErr: err => {
    return {
      type: actions.MODAL_REQUEST_ERR,
      err,
    };
  },
};

export default actions;
