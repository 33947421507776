import actions from "./actions";
import { RequestInviteService } from "../../Services/RequestInviteService";
import { openNotificationWithIcon } from "../../components/notification/Nofication";

const { modalRequestBegin, modalRequestSuccess, modalRequestErr } = actions;

const requestInvite = (data, onSent) => {
  return async (dispatch) => {
    try {
      dispatch(modalRequestBegin());
      const res = await RequestInviteService(data);
      dispatch(modalRequestSuccess());
      onSent(res);
    } catch (err) {
      modalRequestErr(err);
      openNotificationWithIcon("error", "Something went wrong");
    }
  };
};

export { requestInvite };
