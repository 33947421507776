import styled from "styled-components";

const AllEventsWrapper = styled.div`
  padding: 5% 3% 6%;
  > .slick-prev {
    left: -50px !important;
  }
  > .slick-track {
    margin-left: inherit !important;
    margin-right: inherit !important;
  }
  @media only screen and (max-width: 768px) {
    padding: 12% 0 12% 8%;
  }
`;

const SliderWrapper = styled.div`
  .slider {
  }

  section {
    display: flex;
    grid-gap: 50px;
    overflow-x: scroll;
    overflow-y: hidden;
    white-space: nowrap;
    -webkit-overflow-scrolling: touch;
    ::-webkit-scrollbar {
      display: none;
    }
  }
  @media only screen and (max-width: 768px) {
    padding-bottom: ${(props) => !props.isLast && "20%"};
    section {
      padding-top: 10%;
      grid-gap: 25px;
    }
  }
`;

const SliderCardWrapper = styled.div`
  .event-img {
    margin-bottom: 6%;
    width: auto;
  }
  white-space: pre-wrap;
  padding: 0 5%;
  img {
    border-radius: 12px;
    width: 90%;
    height: 3rem;
    object-fit: contain;
  }
  .slider-data {
    padding: 0px 2%;
  }
  .text {
    font-family: Rubik;
    font-style: normal;
    letter-spacing: 0.01em;
  }
  .title {
    font-size: 1.5vw;
    line-height: 2vw;
    color: #fff;
    display: block;
    padding-bottom: 2%;
  }
  .date {
    display: block;
    font-size: 16px;
    line-height: 41px;
    text-transform: uppercase;
    color: #c4c4c4;
    padding-bottom: 3%;
  }
  @media only screen and (max-width: 768px) {
    padding: 0;
    img {
      height: 3rem;
      width: auto;
    }
    .title {
      font-size: 18px;
      line-height: 23px;
    }
    .date {
      font-size: 10px;
      line-height: 25px;
    }
    .event-img {
      height: auto;
    }
  }
`;

export { SliderWrapper, SliderCardWrapper, AllEventsWrapper };
