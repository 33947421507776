import styled from "styled-components";

const ObjectiveWrapper = styled.div`
  background: linear-gradient(159.33deg, #df496d 4.13%, #451e7a 98.56%);
  margin-top: -12%;
  padding-top: 33%;
  padding: 0 11%;
  .objective-container {
    display: flex;
    padding: 23% 0 12% 0;
    .content {
      padding-right: 5%;
      padding-top: 7%;
      .tag-line {
        padding: 4% 0;
        color: #460022;
      }
      .desc {
        padding: 1% 0 3% 0;
      }

      ul {
        list-style: none;
        padding: 0;
        li {
          padding-bottom: 4%;
          font-family: Sen;
          font-style: normal;
          font-weight: 400;
          font-size: 1.15vw;
          line-height: 1.8vw;
          letter-spacing: 0.02em;
          color: #fff;
        }
      }
    }
    .objective-img {
      height: 45vw;
    }
  }
  @media only screen and (max-width: 768px) {
    padding: 0 7%;
    .objective-container {
      flex-direction: column;
      .content {
        padding-right: 0;
        padding-bottom: 12%;

        ul {
          li {
            padding-bottom: 1.5rem;
            font-size: 15px;
            line-height: 28px;
          }
        }
      }
      .objective-img {
        width: 100%;
        height: 40rem;
        padding: 10% 0;
        object-fit: contain;
      }
    }
  }
  @media only screen and (max-width: 550px) {
    .objective-container {
      .objective-img {
        height: auto;
      }
    }
  }
`;

export { ObjectiveWrapper };
