import styled from "styled-components";

export const DescLightWrapper = styled.div`
  display: inline;
  font-family: Sen;
  font-size: 1.3vw;
  line-height: 2vw;
  color: ${(props) => props.color || "#fff"};
  @media only screen and (max-width: 768px) {
    font-size: 16px;
    line-height: 24px;
  }
`;
export const HeadingBoldWrapper = styled.div`
  display: inline;
  font-family: Sen;
  font-weight: 600;
  font-size: 3vw;
  line-height: 3.5vw;
  color: #fff;
  @media only screen and (max-width: 768px) {
    font-size: 32px;
    line-height: 38px;
  }
`;
export const FontLightWrap = styled.div`
  display: inline;
  font-family: Sen;
  font-style: normal;
  font-weight: 400;
  font-size: 1.4vw;
  line-height: 2vw;
  text-align: center;
  letter-spacing: 0.02em;
  color: #fff;
  @media only screen and (max-width: 768px) {
    font-size: 20px;
    line-height: 28px;
  }
`;
export const TitleWrpper = styled.div`
  display: inline;
  font-family: Sen;
  font-style: normal;
  font-weight: 400;
  font-size: 1.4vw;
  line-height: 2.2vw;
  text-align: center;
  letter-spacing: 0.2em;
  text-transform: uppercase;
  color: #f2b109;
  @media only screen and (max-width: 768px) {
    font-size: 20px;
    line-height: 28px;
  }
`;

export const TitleWrapperYellow = styled(TitleWrpper)`
  display: inline;
  color: #ffe840;
`;

export const TitleWrapperBlue = styled(TitleWrpper)`
  display: inline;
  color: #43dff6;
`;

export const SubTitleWrapper = styled.div`
  display: inline;
  font-family: Sen;
  font-style: normal;
  font-weight: 700;
  font-size: 3.5vw;
  line-height: 100%;
  letter-spacing: -0.03em;
  @media only screen and (max-width: 768px) {
    font-size: 41px;
    line-height: 48px;
  }
`;

export const AboutPageTextWrapper = styled.span`
  font-family: Sen;
  font-style: normal;
  font-weight: 400;
  font-size: 1.2vw;
  line-height: 1.8vw;
  color: #aab2db;
  @media only screen and (max-width: 768px) {
    font-size: 16px;
    line-height: 21px;
  }
`;

export const AboutPageHeadingWrapper = styled.span`
  font-family: Sen;
  font-style: normal;
  font-weight: 700;
  font-size: 3.5vw;
  line-height: 3.6vw;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: -0.03em;
  color: #ffffff;
  @media only screen and (max-width: 768px) {
    font-size: 41px;
    line-height: 100%;
    letter-spacing: -0.03em;
  }
`;
