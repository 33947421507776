import styled from "styled-components";
import {
  BannerAboutPageWrap,
  HeroWrapperAbout,
} from "../../../AboutPage/overview/BannerAboutPage/style";

export const HeroWrapperTeam = styled(HeroWrapperAbout)`
  .lines {
    padding-bottom: 7%;
  }
  @media only screen and (max-width: 768px) {
    .lines {
      padding-bottom: 5%;
    }
  }
`;

export const BannerTeamPageWrap = styled(BannerAboutPageWrap)`
  padding: 0px 6% 30% 8%;
  section {
    .hero_data {
      width: 42%;
      .headline {
        font-size: 4.2vw;
        line-height: 4.5vw;
        .colored {
          font-size: 6vw;
          background: linear-gradient(
            272.59deg,
            #ffa589 18.68%,
            #ff43ca 109.55%
          );
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
      }
    }
    .hero_img {
      img {
        width: 40vw;
      }
    }
  }

  @media only screen and (max-width: 768px) {
    height: auto;

    section {
      flex-direction: column;
      .hero_data {
        width: auto;
        padding-top: 16%;
        .hero-title {
          padding-bottom: 4%;
        }
        .headline {
          font-size: 41px;
          line-height: 100%;
          letter-spacing: -0.03em;
          margin-top: 16%;
          .colored {
            font-size: 50px;
            background: linear-gradient(
              272.59deg,
              #ffee59 18.68%,
              #ff43ca 109.55%
            );
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
          }
        }
      }

      .desc {
        margin: 8% 0;
      }
      .hero_img {
        img {
          width: 100%;
          padding-top: 2%;
          margin-top: auto;
        }
      }
    }
  }
`;
