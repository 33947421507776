import styled from "styled-components";

export const CardWrapper = styled.div`
  position: relative;
  width: 275px;
  height: 415px;
  cursor: pointer;

  .card-header {
    position: absolute;
    top: 5%;
    left: 5%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 90%;
    color: white;
  }

  .logo {
    width: 150px;
  }

  .card-title {
    font-family: Sen;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    color: #43ffdd;
    text-transform: uppercase;
    letter-spacing: 0.2em;
  }

  .card-desc {
    position: absolute;
    bottom: 5%;
    left: 5%;
    width: 90%;
    color: white;
    text-align: left;
    font-family: Sen;
    font-style: normal;
    letter-spacing: 0.05em;
    font-size: 14px;
  }

  .world-img {
    border-radius: 16px;
  }

  /* :hover {
    .world-img {
      transition: all ease-in-out 300ms;
      transform: scale(1.05);
    }
  } */

  @media only screen and (max-width: 768px) {
    height: 350px;
  }

  @media only screen and (max-width: 380px) {
    .world-img {
      width: 250px;
      height: 350px;
    }
    .card-header,
    .card-desc {
      width: 80%;
    }
  }
`;
