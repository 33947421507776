import React from "react";
import { Link } from "react-router-dom";
import { AwardsWrap, EaryAccessCard } from "./style";
import WowlabzLogo from "../../../../static/assets/AboutPageAssets/wowlabz-logo.png";
import AwardsWowL from "../../../../static/assets/AboutPageAssets/awards-wowlabz.png";
import { REFORM_URL } from "../../../../utils/constant";
import { Button } from "../../../../components/Button/Button";
import { AboutPageText } from "../../../../components/Headings";

const Awards = () => {
  const isTeamPage = window.location.pathname.includes("/team");
  return (
    <AwardsWrap>
      <EaryAccessCard>
        <div className="pitch-line">
          Apply for our upcoming cohorts to jumpstart your journey to the
          Metaverse
        </div>
        <a href={REFORM_URL} className="join-now" target="_blank">
          <Button>Get Early Access &nbsp; &nbsp;></Button>
        </a>
      </EaryAccessCard>
      <section>
        <div className="title"> Powered by</div>
        <div className="awards-data">
          <div className="texts">
            <img src={WowlabzLogo} className="wow-logo" />
            <div>
              <AboutPageText>
                One of India’s leading tech product development firms.
              </AboutPageText>
            </div>
          </div>
          <div className="awards-wrap">
            <img src={AwardsWowL} alt="awards-img" className="awards-img" />
          </div>
        </div>
      </section>
      {!isTeamPage && (
        <div className="meet-team-text">
          <Link to="/team">Meet The Team ></Link>
        </div>
      )}
    </AwardsWrap>
  );
};

export default Awards;
