import styled from "styled-components";

export const AwardsWrap = styled.div`
  padding: 18% 10% 10% 12%;
  .title {
    font-family: Rubik;
    font-style: normal;
    font-weight: 600;
    font-size: 28px;
    line-height: 41px;
    letter-spacing: -0.04em;
    color: #b766f3;
  }

  position: relative;
  background: linear-gradient(158.19deg, #6d2cc1 0%, #3b37ff 98.5%);

  .awards-data {
    display: flex;
    padding: 3% 0;
    .texts {
      padding: 2% 6% 2% 0;
      border-right: 1px solid #967cff;
      width: 28%;

      .wow-logo {
        height: 5vw;
      }
    }
  }
  .awards-wrap {
    width: 65%;
  }
  .awards-img {
    padding-left: 6%;
    padding-top: 4%;
    width: fill-available;
    object-fit: contain;
  }
  .meet-team-text {
    font-family: Sen;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    color: #43ffdd;
  }

  @media only screen and (max-width: 768px) {
    padding-top: 15rem;
    .awards-data {
      flex-direction: column;
      .texts {
        padding: 1rem 6% 2rem 0;
        border-bottom: 1px solid #967cff;
        border-right: none;
        width: 100%;
        .wow-logo {
          height: 4rem;
        }
      }
    }
    .awards-wrap {
      overflow-y: scroll;
      margin-right: -12%;
      margin-left: -14%;
      width: auto;
      .awards-img {
        padding-left: 0;
        padding-top: 2rem;
        padding-left: 2rem;
        width: 42rem;
      }
    }

    .meet-team-text {
      padding-top: 2rem;
    }
  }
`;

export const EaryAccessCard = styled.div`
  width: 80%;
  padding: 4% 10%;
  background: linear-gradient(254.69deg, #ff9c7c 2.69%, #ec16ff 97.05%);
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 10px;
  z-index: 1;
  margin: -10% auto auto auto;
  top: 0;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  .pitch-line {
    font-family: Sen;
    font-style: normal;
    font-weight: 700;
    font-size: 2.4vw;
    line-height: 3vw;
    padding-bottom: 3%;
    text-align: center;
    color: #ffffff;
  }

  @media only screen and (max-width: 768px) {
    margin: -11rem auto auto auto;
    padding: 8% 10%;
    height: auto;
    width: 87%;
    flex-direction: column;
    .pitch-line {
      font-size: 31px;
      line-height: 37px;
      padding-bottom: 1.2rem;
    }
  }
  @media only screen and (max-width: 550px) {
    padding: 10%;
  }
`;
