import styled from "styled-components";

export const FooterTitle = styled.span`
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 1.3vw;
  line-height: 2vw;
  color: ${({ theme }) => theme.white};
  @media only screen and (max-width: 768px) {
    font-size: 20px;
    line-height: 28px;
  }
`;

export const FooterText = styled.span`
  font-family: Rubik;
  font-style: normal;
  font-weight: 400;
  font-size: 1vw;
  line-height: 2.5vw;
  color: ${({ theme }) => theme["off-white"]};
  @media only screen and (max-width: 768px) {
    font-size: 16px;
    line-height: 38px;
  }
`;
export const FooterTextInvest = styled.span`
  font-family: Rubik;
  font-style: normal;
  font-weight: 400;
  font-size: 1vw;
  line-height: 1.6vw;
  color: ${({ theme }) => theme["off-white"]};
  @media only screen and (max-width: 768px) {
    font-size: 16px;
    line-height: 26px;
  }
`;

export const FooterWrapper = styled.div`
  background: ${({ theme }) => theme["dark-blue"]};
  color: ${({ theme }) => theme.white};
  padding: 5% 6% 10% 6%;
  display: flex;
  justify-content: space-between;
  .footer-btn {
    background: ${({ theme }) => theme["invest-btn"]};
    border-radius: 8px;
    padding: 2% 4%;
    font-weight: 700;
    max-width: 10rem;
    text-align: center;
    font-size: 1vw;
  }
  .img-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    img {
      object-fit: contain;
    }
    .social-logo {
      height: 3rem;
      margin-bottom: 2.5rem;
    }
    .web3-logo {
      height: 4rem;
    }
  }
  .socials {
    .title {
      line-height: 3.5vw;
    }
    .join-us-wrap {
      padding: 1% 0;
      img {
        padding: 1% 1rem 0 0;
      }
    }
    .links {
      padding: 0 0 2.5rem 0;
      img {
        padding: 1% 2rem 0 0;
      }
    }
  }
  .quick-links {
    ul {
      list-style-type: none;
      padding: 0;
    }
  }

  .invest-section {
    .invest-text {
      padding: 1.5vw 0;
    }
  }
  @media only screen and (max-width: 768px) {
    flex-direction: column;
    padding: 3rem 2rem 2rem 2rem;
    .socials {
      padding: 3.5rem 0 2.5rem 0;
    }
    .quick-links {
      padding: 1rem 0 2.4rem 0;
    }
    .invest-section {
      .invest-text {
        padding: 1.5rem 0;
      }
    }
  }
  @media only screen and (max-width: 430px) {
    .footer-btn {
      font-size: 0.75rem;
    }
  }
`;
