import styled from "styled-components";

export const DropdownWrapper = styled.div`
  user-select: none;
  position: relative;
  width: fit-content;
  margin: ${(props) => props.background === "dark" && "0 0.8rem"};
  .dropdown-btn {
    padding: ${(props) =>
      props.background === "dark" || props.background === "darker"
        ? "0.5rem 1rem"
        : "0 1rem"};
    color: white;
    font-weight: 500;
    display: flex;
    align-items: center;
    cursor: pointer;
    justify-content: space-between;
    border-radius: 2px;
    .selected {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      padding: ${(props) =>
        props.background === "dark" ? "0 1rem" : "0 0.5rem 0 0"};
    }
  }
  .dark {
   background: linear-gradient(92.23deg, #FF56F6 21.43%, #B936EE 50.63%, #3BACE2 100%, #406AFF 117.04%);
  }
  .darker{
    background: #353648;

  }
  .dropdown-content {
    position: absolute;
    top: ${(props) => (props.direction === "up" ? "auto" : "110%")};
    bottom: ${(props) => (props.direction === "up" ? "110%" : "auto")}
    left: 0;
    width: 92%;
    text-align: left;
    padding: 10px;
    background: rgba(49, 49, 49, 0.95);
    border-radius: 10px;
    box-shadow: 3px 3px 10px 6px rgba(0, 0, 0, 0.06);
    font-weight: 500;
    color: white;
    z-index: 4;
    .dropdown-item {
      padding: 10px;
      cursor: pointer;
      transition: all 0.2s;
      &:hover {
        background: black;
      }
    }
  }
  @media only screen and (max-width: 1024px) {
    margin: 0;
    width:100%;
    .dropdown-btn {
      padding: ${(props) =>
        props.background === "dark" ? "0.5rem 1rem" : "0 0.2rem"};
      .selected {
        padding: ${(props) =>
          props.background === "dark" ? "0 0.5rem" : "0 0.5rem 0 0"};
      }
    }
  }
`;
