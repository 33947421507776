import React, { useEffect } from "react";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { CTAwrap, PathWrapper } from "./style";
import { FontDescV1, SubTitle, Title } from "../../components/Headings";
import HowItWorksImg from "../../static/assets/socialli-metaverse-land-parcels (1).png";
import HowItWorksImgMobile from "../../static/assets/socialli-metaverse-land-parcels-mobile.png";
import TeamImg from "../../static/assets/bring-your-community-to-metaverse.png";
import { useIsTab } from "../../utils/utils";
import { Button } from "../../components/Button/Button";
import { REFORM_URL } from "../../utils/constant";
import { fadeInShow } from "../../utils/motion";

export const Path = () => {
  const isTab = useIsTab();
  const [ref, inView] = useInView({
    threshold: 0.2,
  });
  const animation = useAnimation();

  useEffect(() => {
    if (inView) {
      animation.start(animation.start(fadeInShow("down", "spring", 0.5, 1)));
    }

    if (!inView) {
      animation.start({
        y: -50,
        opacity: 0,
      });
    }
  }, [inView]);

  return (
    <motion.div style={{ position: "relative" }}>
      <CTAwrap isTab={isTab}>
        <img src={TeamImg} alt="team" className="team" />
        <section className="CTA-content">
          <div className="title">Bring your Community to the Metaverse</div>
          <FontDescV1>
            <p>
              Each metaverse will be equipped with its own social token & NFTs
              with built-in DeFi and GameFi opportunities (no full-stop)
            </p>
          </FontDescV1>

          <div className="interested">
            <a href={REFORM_URL} target="_blank" className="join-now">
              Apply for one of our upcoming cohorts >
            </a>
          </div>
        </section>
      </CTAwrap>
      <PathWrapper>
        <motion.section ref={ref} animate={animation} className="section">
          <Title>HOW IT WORKS</Title>
          <div className="tag-line">
            <SubTitle>The Metaverse Revolution is Here!</SubTitle>
          </div>
          <p className="desc">
            <FontDescV1>
              🏚 Own your home in the metaverse. 🚀️ Teleport anywhere in the
              multiverse.
              <br />
              🪙 Engage and earn!
            </FontDescV1>
          </p>
        </motion.section>
        <motion.img
          src={isTab ? HowItWorksImgMobile : HowItWorksImg}
          alt="path"
          className="how-it-works"
          initial={{ opacity: 0 }}
          transition={{
            type: "tween",
            duration: 0.5,
            delay: 1,
            ease: "easeIn",
          }}
          whileInView={{ opacity: 1 }}
        />
        <a href={REFORM_URL} target="_blank" className="join-now">
          <Button>Join the tribe &nbsp; &nbsp;></Button>
        </a>
      </PathWrapper>
    </motion.div>
  );
};
