import React from "react";
import Slider from "react-slick";

import { NoEventsWrapper, SliderCardWrapper, SliderWrapper } from "../style";
import { HeadingBold } from "../../../components/Headings";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useIsTab } from "../../../utils/utils";
import { DescLightWrapper } from "../../../components/Headings/style";

const EventComponent = ({ events, isLast }) => {
  const isTab = useIsTab();
  const settings = {
    dots: true,
    infinite: false,
    speed: 700,
    slidesToShow: 5,
    slidesToScroll: 3,
  };
  const SliderCard = ({ img }) => {
    return (
      <SliderCardWrapper>
        <div className="event-img">
          <img src={img} alt="event" />
        </div>
      </SliderCardWrapper>
    );
  };

  const TabView = ({ events }) => {
    return (
      <section>
        {events?.map((item) => {
          const { name, img } = item;
          return <SliderCard img={img} key={name} />;
        })}
      </section>
    );
  };

  const WebView = ({ events }) => {
    return (
      <div className="slider">
        <Slider {...settings}>
          {events?.map((item) => {
            const { name, img } = item;
            return <SliderCard img={img} key={name} />;
          })}
        </Slider>
      </div>
    );
  };

  return (
    <SliderWrapper isLast={isLast}>
      {isTab ? <TabView events={events} /> : <WebView events={events} />}
      {/* <TabView events={events} />{" "} */}
    </SliderWrapper>
  );
};

export default EventComponent;
