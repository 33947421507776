import React, { useEffect } from "react";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { ObjectiveWrapper } from "./style";
import ObjectiveImg from "../../static/assets/metaverse-tools-creator-economy.png";
import { FontDescV1, SubTitle } from "../../components/Headings";
import { Button } from "../../components/Button/Button";
import { TitleWrapperYellow } from "../../components/Headings/style";
import { REFORM_URL } from "../../utils/constant";
import { fadeInShow } from "../../utils/motion";

export const Objective = () => {
  const { ref, inView } = useInView({
    threshold: 0.2,
  });
  const animation = useAnimation();

  useEffect(() => {
    if (inView) {
      animation.start(fadeInShow("down", "spring", 0.5, 1));
    }

    if (!inView) {
      animation.start({
        y: -50,
        opacity: 0,
      });
    }
  }, [inView]);

  return (
    <ObjectiveWrapper>
      <motion.div animate={animation} className="objective-container" ref={ref}>
        <section className="content">
          <TitleWrapperYellow>
            Build Your Virtual World & Its Economy
          </TitleWrapperYellow>
          <div className="tag-line">
            <SubTitle>
              Don’t miss out on the next frontier of the creator economy 💸
            </SubTitle>
          </div>
          <motion.p
            initial={{ opacity: 0 }}
            transition={{
              type: "tween",
              duration: 0.5,
              delay: 0.5,
              ease: "easeIn",
            }}
            whileInView={{ opacity: 1 }}
            className="desc"
          >
            <FontDescV1>
              The metaverse is the next age of the internet, social.li can help
              you launch your own. Get the infrastructure, tools, and
              interlinked community support to create a prosperous metaverse.
            </FontDescV1>
          </motion.p>

          <motion.a
            initial={{ opacity: 0 }}
            transition={{
              type: "tween",
              duration: 0.5,
              delay: 0.5,
              ease: "easeIn",
            }}
            whileInView={{ opacity: 1 }}
            href={REFORM_URL}
            className="join-now"
            target="_blank"
          >
            <Button>Register your Interest &nbsp; &nbsp;></Button>
          </motion.a>
        </section>
        <div>
          <img
            src={ObjectiveImg}
            alt="objective-img"
            className="objective-img"
          />
        </div>
      </motion.div>
    </ObjectiveWrapper>
  );
};
