import React from "react";
import {
  AboutPageHeadingWrapper,
  AboutPageTextWrapper,
  DescLightWrapper,
  FontLightWrap,
  HeadingBoldWrapper,
  SubTitleWrapper,
  TitleWrapperBlue,
  TitleWrapperYellow,
  TitleWrpper,
} from "./style";

export const HeadingBold = ({ children, letterSpacing }) => {
  return (
    <HeadingBoldWrapper letterSpacing={letterSpacing}>
      {children}
    </HeadingBoldWrapper>
  );
};

export const DescLight = ({ children, color }) => {
  return <DescLightWrapper color={color}>{children}</DescLightWrapper>;
};

export const FontDescV1 = ({ children }) => {
  return <FontLightWrap>{children}</FontLightWrap>;
};

export const Title = ({ children }) => {
  return <TitleWrpper>{children}</TitleWrpper>;
};

export const TitleYellow = ({ children }) => {
  return <TitleWrapperYellow>{children}</TitleWrapperYellow>;
};
export const TitleBlue = ({ children }) => {
  return <TitleWrapperBlue>{children}</TitleWrapperBlue>;
};
export const SubTitle = ({ children }) => {
  return <SubTitleWrapper>{children}</SubTitleWrapper>;
};

export const AboutPageText = ({ children }) => {
  return <AboutPageTextWrapper>{children}</AboutPageTextWrapper>;
};

export const AboutPageHeadline = ({ children }) => {
  return <AboutPageHeadingWrapper>{children}</AboutPageHeadingWrapper>;
};
