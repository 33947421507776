import styled from "styled-components";

export const TeamGridWrap = styled.div`
  background: ${({ theme }) => theme["light-blue-color"]};
  padding: 0 14% 10%;

  .first-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(32%, auto));
    grid-gap: 35px;
  }
  .team-grid {
    padding: 4% 0;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(26%, auto));
    row-gap: 35px;
    -webkit-column-gap: 55px;
    column-gap: 55px;
  }

  .title {
    font-family: Sen;
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 120%;
    text-align: center;
    letter-spacing: 0.2em;
    text-transform: uppercase;
    color: #ba63ff;
    padding-bottom: 2rem;
  }

  @media only screen and (max-width: 768px) {
    padding: 0 8% 10%;
    .title {
      font-size: 25px;
    }
    .first-grid {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(50%, auto));
      grid-gap: 35px;
    }
    // .team-grid {
    //   display: grid;
    //   grid-template-columns: repeat(auto-fill, minmax(26%, auto));
    //   grid-gap: 35px;
    // }
  }
`;
export const ProfileComponentWrap = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  .profile-texts {
    padding: 1rem 0;
    text-align: center;
    .name {
      font-family: Sen;
      font-style: normal;
      font-weight: 700;
      font-size: 30px;
      line-height: 45px;
      text-align: center;
      color: ${({ theme }) => theme["cyan-color"]};
    }
  }
  .profile-img {
    height: 12rem;
    object-fit: contain;
  }
  .icons {
    height: 1.8rem;
    object-fit: contain;
    padding: 0 10px;
  }
  .Amit,
  .Roshit {
    height: 18rem;
  }
  @media only screen and (max-width: 768px) {
    .profile-img {
      height: 8rem;
    }
    .Amit,
    .Roshit {
      height: 14rem;
    }
    .profile-texts {
      .name {
        font-size: 20px;
        line-height: 25px;
      }
    }
  }
`;
