import styled from "styled-components";

export const WorldsWrapper = styled.div`
  background: #2e028d;
  padding: 8% 12%;
  text-align: center;
  .world-header {
    padding-bottom: 1%;
    text-align: left;
  }

  .tag-line {
    color: #fff;
    padding: 1% 0;
  }

  .slider {
    position: relative;
    margin-top: 4%;
    z-index: 10;
  }

  .slick-track {
    padding: 25px 0;
  }

  .slick-arrow.left {
    position: absolute;
    width: 7rem;
    height: 4rem;
    left: -5.4rem;
    top: 40%;
    .left:hover {
      cursor: pointer;
    }
  }

  .slick-arrow.right {
    position: absolute;
    width: 4rem;
    height: 4rem;
    right: -5.4rem;
    top: 40%;
    .right:hover {
      cursor: pointer;
    }
  }
  .gradient {
    position: absolute;
    width: 1600px;
    height: 390px;
    left: -100px;
    top: 3960px;
    background: linear-gradient(150deg, #7003fb 13.4%, #5606ff 86.6%);
    filter: blur(150px);
  }
  .show-worlds {
    display: flex;
    align-items: center;
    float: right;
    width: 100px;
    color: #43ffdd;
    cursor: pointer;

    .view-btn {
      font-family: Sen;
      font-size: 14px;
      color: #43ffdd;
      margin-right: 15%;
      letter-spacing: 1px;
      font-weight: 600;
    }
  }

  .slick-slide > div {
    display: grid;
    place-items: center;
    margin-top: 50px;
    margin: auto;
    /* height: 500px; */
    padding: 0px;
  }

  @media only screen and (max-width: 1445px) {
    .gradient {
      display: none;
    }
  }

  @media only screen and (max-width: 769px) {
    .slider {
      margin-top: 8%;
    }
    padding: 8%;
    .tag-line {
      padding: 2rem 0;
    }
  }

  @media only screen and (max-width: 430px) {
    .slick-arrow.left {
      /* display: none; */
      left: -4.4rem;
    }

    .slick-arrow.right {
      /* display: none;  */
      right: -2rem;
    }
  }

  @media only screen and (max-width: 330px) {
    .slick-list {
      width: 275px;
      height: 415px;
    }
  }
`;
