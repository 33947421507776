const primaryColor = "#320391";
const btnColor = "#2d491f";
const bgColorApp = "#1a1537";
const cyanColor = "#38ffdc";
const cyanColorV2 = "#00D5C8";
const readMoreText = "#2fd69d";
const lightBlue = "#320391";
const purpleTextColor = "#b766f3";
const offWhite = "#f2f2f2";
const darkBlue = "#100047";
const investBtnColor = "#1760f7";
const partnerBg = "#2e028d";
const redColor = "#460022";
const whiteColor = "#ffffff";

const theme = {
  "primary-color": primaryColor,
  "btn-color": btnColor,
  "bg-color-app": bgColorApp,
  "cyan-color": cyanColor,
  "cyan-color-v2": cyanColorV2,
  "read-more-color": readMoreText,
  "light-blue-color": lightBlue,
  "purple-text-color": purpleTextColor,
  "off-white": offWhite,
  "dark-blue": darkBlue,
  "invest-btn": investBtnColor,
  "partner-bg": partnerBg,
  "red-color": redColor,
  white: whiteColor,
};

export { theme };
