import styled from "styled-components";

export const JoinUSCardWrap = styled.div`
  padding: 5% 11%;
  background: linear-gradient(158.19deg, #200064 0%, #100047 98.5%);
  display: flex;
  justify-content: space-between;
  color: #fff;
  .join-img {
    width: 35vw;
    object-fit: contain;
  }
  .join-data {
    width: 32vw;
    padding-right: 3%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .title {
      font-size: 4.2vw;
      line-height: 4.5vw;
      font-family: Sen;
      font-style: normal;
      font-weight: 700;
      letter-spacing: -0.03em;
      color: #fff;

      .colored {
        font-size: 6vw;
        background: linear-gradient(272.59deg, #ffa589 18.68%, #ff43ca 109.55%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }
    .desc {
      padding: 2rem 0;
      font-weight: 700;
    }
    .see-available {
      font-family: Sen;
      font-style: normal;
      font-weight: 700;
      line-height: 24px;
      font-size: 1.3vw;
      color: #43ffdd;
      padding-top: 1rem;
    }
  }

  @media only screen and (max-width: 768px) {
    padding: 5% 8% 4rem 8%;
    flex-direction: column;
    .join-img {
      width: 100%;
    }
    .join-data {
      width: 100%;
      padding-top: 2rem;
      padding-right: 0;
      .title {
        font-size: 41px;
        line-height: 100%;
        letter-spacing: -0.03em;
        .colored {
          font-size: 50px;
          background: linear-gradient(
            272.59deg,
            #ffee59 18.68%,
            #ff43ca 109.55%
          );
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
      }
      .desc {
        padding: 1.2rem 0;
      }
      .see-available {
        padding-top: 0;
        font-size: 16px;
        line-height: 19px;
      }
    }
  }
`;
