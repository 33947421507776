import styled from "styled-components";
import Box from "../../static/assets/box-1.png";

export const CTAwrap = styled.div`
  width: 85%;
  height: 26%;
  background: ${(props) =>
    props.isTab
      ? `url(${Box})`
      : `linear-gradient(
    93.98deg,
    #1affb0 0.31%,
    #18bcff 32.06%,
    #309cff 67.47%,
    #ff55f8 100.27%
  )`};
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: space-around;
  border-radius: 10px;
  z-index: 1;
  margin: -11% auto auto auto;
  top: 0;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  .team {
    width: 40%;
    margin-top: -10%;
    z-index: 2;
    object-fit: contain;
  }
  .CTA-content {
    width: 43%;
    padding-right: 5%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .title {
      font-family: Sen;
      font-style: normal;
      font-weight: 700;
      font-size: 2.4vw;
      line-height: 2.8vw;
      display: flex;
      align-items: flex-end;
      letter-spacing: -0.03em;
      color: #31318b;
    }
    .interested {
      font-family: Sen;
      font-style: normal;
      font-weight: 700;

      line-height: 24px;
      font-size: 1.3vw;
      color: #43ffdd;
      padding-top: 1rem;
    }
  }
  @media only screen and (max-width: 768px) {
    margin: -11rem auto auto auto;
    padding: 6%;
    width: 87%;
    border-radius: 15px;
    height: auto;
    flex-direction: column;
    .team {
      width: 80%;
      margin: -30% auto auto;
    }
    .CTA-content {
      width: 100%;
      .title {
        font-size: 34px;
        line-height: 42px;
      }
      .interested {
        font-size: 20px;
        color: #fff;
      }
    }
  }
  @media only screen and (max-width: 550px) {
    .team {
      width: 90%;
    }
    .interested {
      padding: 1rem 0;
    }
  }
`;

export const PathWrapper = styled.div`
  background: linear-gradient(158.19deg, #200064 0%, #100047 98.5%);
  clip-path: polygon(0 0, 100% 0%, 100% 90%, 0% 100%);
  padding-bottom: 10%;
  display: flex;
  flex-direction: column;
  @media only screen and (max-width: 768px) {
    clip-path: polygon(0 0, 100% 0%, 100% 97%, 0% 100%);
  }
  padding: 20% 0 10% 0;
  .section {
    width: 56%;
    margin: auto;
    text-align: center;
  }
  .tag-line {
    color: #ed5579;
  }
  .how-it-works {
    width: 95%;
    margin: auto;
    padding-bottom: 1rem;
  }
  .join-now {
    margin: auto;
    margin-top: -2rem;
    margin-bottom: 2rem;
  }
  @media only screen and (max-width: 768px) {
    padding: 28rem 0 6rem 0;
    .section {
      width: 90%;
    }
    .title {
      font-size: 22px;
      line-height: 30px;
    }
    .desc {
      padding: 1rem 0;
    }
    .join-now {
      margin: 1rem auto 1rem;
    }
  }
`;
