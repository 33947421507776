import actions from "./actions";

const {
  MODAL_REQUEST_BEGIN,
  MODAL_REQUEST_SUCCESS,
  MODAL_REQUEST_ERR,
} = actions;

const initState = {
  loading: false,
  error: null,
};

const ModalReducer = (state = initState, action) => {
  const { type, err } = action;
  switch (type) {
    case MODAL_REQUEST_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case MODAL_REQUEST_SUCCESS:
      return {
        ...state,
        loading: false,
      };

    case MODAL_REQUEST_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    default:
      return state;
  }
};
export default ModalReducer;
