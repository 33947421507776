import styled from "styled-components";
import BgImage from "../../static/assets/hero_background.jpg";

const HeroWrapper = styled.div`
  position: relative;
  .lines {
    position: absolute;
    bottom: 0;
    padding-bottom: 6%;
    width: 100%;
  }
  @media only screen and (max-width: 768px) {
    .lines {
      padding-bottom: 30%;
    }
  }
`;

const BannerWrapper = styled.div`
  background: url(${BgImage});
  background-repeat: no-repeat;
  background-size: cover;
  padding: 0px 8% 16% 8%;
  header {
    padding-top: 7%;
    img {
      width: 15%;
    }
  }
  section {
    display: flex;
    justify-content: space-between;
    .hero_data {
      padding-top: 8%;
      width: 37%;
    }
    .headline {
      font-family: Sen;
      font-style: normal;
      font-weight: 700;
      font-size: 6.5vw;
      line-height: 5.6vw;
      letter-spacing: -0.03em;
      color: #fff;
      .colored {
        background: linear-gradient(272.59deg, #ffee59 18.68%, #ff43ca 109.55%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
      }
    }
    .desc {
      display: block;
      font-family: Jura;
      font-size: 1.3vw;
      line-height: 2vw;
      margin: 2.5vw 0;
      color: #fff;
    }
    .hero_img {
      img {
        width: 50vw;
        margin-top: -17%;
      }
    }
  }
  @media only screen and (max-width: 768px) {
    padding: 0 8% 15rem 8%;
    height: auto;
    header {
      padding-top: 10%;
      img {
        width: 40%;
      }
    }
    section {
      flex-direction: column;
      .hero_data {
        width: auto;
      }
      .headline {
        font-size: 53px;
        line-height: 91.81%;
        margin-top: 6%;
      }
      .desc {
        font-size: 16px;
        line-height: 24px;
        margin: 8% 0;
      }
      .hero_img {
        img {
          width: 100%;
          padding-top: 2%;
          margin-top: auto;
        }
      }
    }
  }
`;
const ObjectiveWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 8vw;
  padding-bottom: 3vw;
  .objective-img {
    width: 36vw;
    object-fit: contain;
  }
  section {
    width: 42vw;
    padding-top: 10%;
    color: #fff;
    .objective-title {
      font-family: Rubik;
      font-weight: 600;
      font-size: 1.9vw;
      line-height: 3vw;
      letter-spacing: -0.04em;
      .first {
        color: ${({ theme }) => theme["cyan-color"]};
      }
    }
    .objective-desc {
      font-family: Jura;
      font-size: 1.3vw;
      line-height: 2vw;
      padding-right: 5rem;
      padding-top: 1.5%;
    }
    .interested {
      padding-top: 1.5%;
    }
  }
  @media only screen and (max-width: 768px) {
    padding-bottom: 10%;
    flex-direction: column-reverse;
    .objective-img {
      width: 100%;
      padding-top: 24px;
      margin-left: -6px;
    }
    section {
      width: auto;
      padding-top: 0;
      .objective-title {
        font-size: 22px;
        line-height: 28px;
      }
      .objective-desc {
        font-size: 16px;
        line-height: 24px;
        padding-top: 6px;
        padding-right: 0;
      }
      .interested {
        padding-top: 6px;
      }
    }
  }
`;

const RazerBladeWrapper = styled.div`
  position: relative;
  background: ${({ theme }) => theme["bg-color-app"]};
  padding: 60px;
`;
const RazerBlade = styled.div`
  &:after {
    position: absolute;
    width: 100%;
    height: 100%;
    content: "";
    background: ${({ theme }) => theme["bg-color-app"]};
    z-index: 0;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform-origin: bottom right;
    transform: skewY(356deg);
  }
`;

export {
  HeroWrapper,
  BannerWrapper,
  ObjectiveWrapper,
  RazerBlade,
  RazerBladeWrapper,
};
