import React from "react";
import { JoinUSCardWrap } from "./style";
import JoinImg from "../../../../static/assets/TeamPageAssets/socially-careers-join-us.png";
import { DescLight } from "../../../../components/Headings";

export const JoinUSCard = () => {
  return (
    <JoinUSCardWrap>
      <img src={JoinImg} className="join-img" alt="join-img" />
      <section className="join-data">
        <div className="title">
          Wanna
          <br />
          <span className="colored">Join us?</span>
        </div>
        <div className="desc">
          <DescLight>
            Our company is only as good as the people on the team. Think you’d
            like to work with us?
          </DescLight>
        </div>
        <div className="see-available">See Available Positions ></div>
      </section>
    </JoinUSCardWrap>
  );
};
