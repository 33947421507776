import React from "react";
import { BannerPageAbout } from "./overview/BannerAboutPage";
import { ValuesVision } from "./overview/ValuesVision";
import { EcoSystem } from "./overview/EcoSystemSlider";
import Awards from "./overview/Awards";
import Timeline from "../Timeline";
import { Footer } from "../Footer";

export const AboutPage = () => {
  return (
    <div>
      <BannerPageAbout />
      <ValuesVision />
      <EcoSystem />
      <Timeline />
      <Awards />
      <Footer />
    </div>
  );
};
