import React from "react";
import { Link } from "react-router-dom";
import Tilt from "react-parallax-tilt";
import { motion } from "framer-motion";
import { BannerWrapper, HeroWrapper } from "./style";
import SocialliLogo from "../../static/assets/social.li logo.png";
import HeroImg from "../../static/assets/socially-hero-image.png";
import LinesBg from "../../static/assets/wave.png";
import { Button } from "../../components/Button/Button";
import { DescLight } from "../../components/Headings";
import { REFORM_URL } from "../../utils/constant";
import { textVariant, fadeIn } from "../../utils/motion";

export const Banner = () => {
  return (
    <>
      <HeroWrapper>
        <BannerWrapper>
          <motion.div
            variants={fadeIn("down", "spring", 0.5, 1)}
            initial="hidden"
            animate="show"
          >
            <header>
              <motion.div>
                <Link to="/">
                  <img src={SocialliLogo} />
                </Link>
              </motion.div>
            </header>
            <motion.section>
              <div className="hero_data">
                <motion.span className="headline">
                  <span className="colored">Your Own</span>
                  <br />
                  Metaverse
                </motion.span>
                <div className="desc">
                  <DescLight>
                    <motion.div>
                      {" "}
                      Everyone will have a home in the metaverse in 2030, you
                      can have it now!
                    </motion.div>
                  </DescLight>
                </div>
                <motion.a href={REFORM_URL} target="_blank">
                  <Button>Join the tribe &nbsp; &nbsp;></Button>
                </motion.a>
              </div>
              <motion.div className="hero_img">
                <Tilt>
                  <img src={HeroImg} />
                </Tilt>
              </motion.div>
            </motion.section>
          </motion.div>
        </BannerWrapper>
        <img alt="lines" src={LinesBg} className="lines" />
      </HeroWrapper>
    </>
  );
};
