import styled from "styled-components";
import BackgroundMobileImage from "../../static/InvestLandingAssets/mask_background_mobile.svg";

export const PageContainer = styled.div`
  background-color: ${({ theme }) => theme["light-blue-color"]};
  background-size: 100% 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media only screen and (max-width: 720px) {
    background-image: url(${BackgroundMobileImage});
  }
`;

export const Header = styled.div`
  display: flex;
  padding: 50px 10% 0 10%;
  justify-content: space-between;

  @media only screen and (max-width: 720px) {
    padding: 16px 24px;
  }
`;

export const Body = styled.div`
  display: flex;
  padding: 16px 10%;
  justify-content: space-between;
  margin: 5% 0 7% 0;
  @media only screen and (max-width: 1210px) {
    height: 80vh;
  }
`;

export const Footer = styled.div`
  display: flex;
  padding: 24px 10%;
  justify-content: space-between;
  background: #100047;

  @media only screen and (max-width: 720px) {
    padding: 16px 24px;
    align-items: center;
  }
`;

export const HeaderButton = styled.button`
  padding: 10px 20px;
  background: #8c30f5;
  border-radius: 6px;
  cursor: pointer;
  border: none;

  @media only screen and (max-width: 720px) {
    display: none;
  }
`;

export const HeaderButtonText = styled.h1`
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  text-transform: uppercase;
  margin: 0;
  color: #ffffff;
`;

export const FooterText = styled.h1`
  margin: 0;
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: #d9dbe1;

  @media only screen and (max-width: 720px) {
    font-weight: 500;
    font-size: 10px;
    line-height: 16px;
  }
`;

export const FooterButtonContainer = styled.div`
  display: flex;
`;

export const BodyContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  color: #fff;
  @media only screen and (max-width: 1210px) {
    width: 80%;
    justify-content: center;
  }
  @media only screen and (max-width: 720px) {
    width: 100%;
  }
`;

export const BodyContentBigText = styled.h1`
  font-family: Inter;
  font-style: normal;
  font-weight: 800;
  font-size: 72px;
  line-height: 98px;
  color: #fff;
  margin: 0;
  @media only screen and (max-width: 1210px) {
    font-size: 55px;
    line-height: 54px;
  }
  @media only screen and (max-width: 720px) {
    font-size: 40px;
    line-height: 54px;
  }
  @media only screen and (max-width: 450px) {
    font-size: 25px;
    line-height: 30px;
  }
`;

export const BodyContentSmallText = styled.h1`
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 32px;
  color: #fff;
  margin: 16px 0 32px 0;
  @media only screen and (max-width: 720px) {
    font-size: 18px;
    line-height: 25px;
  }
`;

export const BodyContentLogo = styled.div`
  display: flex;
  @media only screen and (max-width: 1210px) {
    display: none;
  }
`;

export const BodyButton = styled.button`
  padding: 16px 48px;
  background: #8c30f5;
  border-radius: 6px;
  cursor: pointer;
  border: none;
  width: max-content;

  @media only screen and (max-width: 720px) {
    padding: 12px 32px;
  }
`;

export const BodyButtonText = styled.h1`
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 18px;
  text-transform: uppercase;
  margin: 0;
  color: #ffffff;
`;
