import React from "react";
import { Link } from "react-router-dom";
import { BannerTeamPageWrap, HeroWrapperTeam } from "./style";
import { DescLight, Title } from "../../../../components/Headings";
import SocialliLogo from "../../../../static/assets/social.li logo.png";
import HeroImg1 from "../../../../static/assets/TeamPageAssets/people-at-socialli.png";
import LinesBg from "../../../../static/assets/wave.png";
import { TeamGrid } from "../TeamsGrid";

export const BannerTeamPage = () => {
  return (
    <>
      <HeroWrapperTeam>
        <BannerTeamPageWrap>
          <header>
            <Link to="/">
              <img src={SocialliLogo} alt="socialli-logo" />
            </Link>
          </header>
          <section>
            <div className="hero_data">
              <div className="hero-title">
                <Title>TEAM SOCIAL.LI</Title>
              </div>

              <span className="headline">
                It’s all about the
                <br />
                <span className="colored"> People!</span>
              </span>

              <div className="desc">
                <DescLight>
                  We have a kickass team who shares the vision of bringing the
                  metaverse technology to the masses. Together, we’re working on
                  making everyone a Metaverse Native.
                </DescLight>
              </div>
            </div>
            <div className="hero_img">
              <img src={HeroImg1} />
            </div>
          </section>
        </BannerTeamPageWrap>
        <img alt="lines" src={LinesBg} className="lines" />
      </HeroWrapperTeam>
    </>
  );
};
