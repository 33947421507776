import styled from "styled-components";

const FeatureWrapper = styled.div`
  background: linear-gradient(158.19deg, #00a3fe 0%, #0fde97 98.5%);
  padding: 8% 12% 12%;
  .feat-title {
    color: #ffe840;
  }
  .feat-sub {
    color: #fff;
    padding: 1.8% 0 3% 0;
  }
  @media only screen and (max-width: 768px) {
    padding: 8% 12% 12rem;
  }

  @media only screen and (max-width: 500px) {
    .feat-sub {
      color: #fff;
      padding: 1rem 0 2rem 0;
    }
  }
`;
const CardWrapper = styled.div`
  padding: 4% 0;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(26%, auto));
  row-gap: 35px;
  column-gap: 55px;
  @media only screen and (max-width: 500px) {
    grid-template-columns: repeat(auto-fill, minmax(100%, auto));
  }
`;
const FeatureCardStyle = styled.div`
  .title {
    font-size: 1.7vw;
    line-height: 1vw;
    font-family: Sen;
    font-weight: 700;
    color: #0e183a;
  }
  .desc {
    font-family: Sen;
    font-weight: 400;
    font-size: 1.15vw;
    line-height: 1.8vw;
    letter-spacing: 0.02em;
  }
  img {
    height: 35%;
  }
  @media only screen and (max-width: 768px) {
    .title {
      font-size: 18px;
      line-height: 18px;
    }
    .desc {
      font-size: 18px;
      line-height: 27px;
    }
  }
`;
export { FeatureWrapper, FeatureCardStyle, CardWrapper };
