import styled from "styled-components";

export const ServiceTabWrapper = styled.div`
  background: #2e028d;
  padding: 8% 12%;
  text-align: center;
  .tag-line {
    color: #fff;
    padding: 1% 0;
  }
  @media only screen and (max-width: 768px) {
    padding: 8%;
    .tag-line {
      padding: 2rem 0;
    }
  }
`;

export const TabWrapper = styled.div`
  div {
    display: flex;
    justify-content: center;
    padding: 1% 0;
  }
`;

export const Tabs = styled.div`
  transition: width 1s ease-in, height 1s ease-in;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-family: Sen;
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  width: 18vw;
  padding: 1%;
  margin: 1%;
  box-shadow: ${(props) =>
    props.active && "0px 5.76002px 139.681px rgba(255, 86, 246, 0.51)"};
  background: ${(props) =>
    props.active
      ? "linear-gradient(92.23deg, #FF56F6 21.43%, #B936EE 50.63%, #3BACE2 100%, #406AFF 117.04%)"
      : "transparent"};
  color: ${(props) => (props.active ? "white" : "#9680C6")};
  border-style: solid;
  border-width: ${(props) => (props.active ? "0px" : "3px")};
  border-image: linear-gradient(75deg, #3225a2, #6f33af) 1;
`;

export const TabContent = styled.div`
  text-align: left;
  display: flex;
  justify-content: space-between;
  .creator {
    height: 36vw;
  }
  .brand {
    height: 36vw;
  }
  .community {
    height: 45vw;
  }
  section {
    padding-top: 3%;
    .tab-title {
      font-family: Sen;
      font-style: normal;
      font-weight: 700;
      font-size: 2.1vw;
      line-height: 4.5vw;
      text-transform: uppercase;
      color: #43ffdd;
    }
    ul {
      padding: 4% 0 4% 1vw;
      li {
        padding-bottom: 4%;
        font-family: Sen;
        font-style: normal;
        font-weight: 400;
        font-size: 1.15vw;
        line-height: 1.8vw;
        letter-spacing: 0.02em;
        color: #fff;
      }
    }
  }
  @media only screen and (max-width: 768px) {
    flex-direction: column;
    section {
      .tab-title {
        font-size: 32px;
        line-height: 45px;
        padding-bottom: 1rem;
      }
      ul {
        padding: 4% 0 4% 4%;
        li {
          padding-bottom: 1.5rem;
          font-size: 15px;
          line-height: 28px;
        }
      }
    }
    .tab-image {
      object-fit: contain;
      height: 36rem;
    }
  }
  @media only screen and (max-width: 550px) {
    .tab-image {
      height: auto;
    }
  }
`;
