import styled from "styled-components";
import { Slider } from "antd";
import { Modal } from "../../components/modals/antd-modals";

export const AmountSlider = styled(Slider)`
  .ant-slider-handle {
    background-color: ${({ theme }) => theme[`web-blue-color`]};
    height: 20px;
    width: 20px;
  }

  .ant-slider-step {
    background-color: #5e5e5e;
  }
`;

export const SentModal = styled(Modal)`
  position: absolute !important;
  top: 40% !important;
  left: 32%;

  @media only screen and (max-width: 1290px) {
    width: 45% !important;
    left: 28%;
  }
  @media only screen and (max-width: 1080px) {
    width: 50% !important;
    left: 25%;
  }
  @media only screen and (max-width: 900px) {
    width: 65% !important;
    left: 18%;
  }
  @media only screen and (max-width: 720px) {
    position: absolute;
    width: 80% !important;
    left: 10%;
  }
`;

export const RequestModal = styled(Modal)`
  position: absolute !important;
  top: 7% !important;
  left: 32%;

  @media only screen and (max-width: 1290px) {
    width: 45% !important;
    left: 28%;
  }
  @media only screen and (max-width: 1080px) {
    width: 50% !important;
    left: 25%;
  }
  @media only screen and (max-width: 900px) {
    width: 65% !important;
    left: 18%;
  }
  @media only screen and (max-width: 720px) {
    position: absolute;
    width: 90% !important;
    top: 3% !important;
    left: 5%;
  }
`;

export const CustomModal = styled(Modal)`
  width: 35% !important;
  position: absolute !important;
  top: 30% !important;
  left: 32%;

  //transform: translate(-50%, -50%) !important;
  .ant-modal-header {
    background-color: #0f0f0f;
    border-bottom: 1px solid #0f0f0f;
    .ant-modal-title {
      color: #fff;
    }
  }
  .ant-modal-content {
    color: #fff !important;
    background-color: #0f0f0f;
    background-repeat: no-repeat;
    background-position: bottom left;
    // background-image: url(${(props) => (props.bgDesign ? SocialLi : "")});
    border-bottom: 1px solid #0f0f0f;
    .ant-modal-body {
      padding: 12px 24px 12px 24px;
    }
  }
  .ant-modal-footer {
    border-top: 1px solid #0f0f0f;
  }
  @media only screen and (max-width: 1290px) {
    width: 45% !important;
    left: 28%;
  }
  @media only screen and (max-width: 1080px) {
    width: 50% !important;
    left: 25%;
  }
  @media only screen and (max-width: 900px) {
    width: 65% !important;
    left: 18%;
  }
  @media only screen and (max-width: 720px) {
    position: absolute;
    width: 90% !important;
    top: 25% !important;
    left: 5%;
  }
`;

export const ShareModalWrapper = styled.div`
  .title {
    font-size: 16px;
  }
  .subtitle {
    font-size: 14px;
    padding: 2px 0px 25px 0px;
  }
  .share-subtitle {
    font-size: 14px;
    line-height: 30px;
    padding: 25px 0px 25px 0px;
  }
  .next {
    display: flex;
    justify-content: flex-end;
  }
  .share-pitch {
    margin-top: 16px;
    width: 100%;
  }
  .input-box {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  padding: 10px;
  section {
    margin: 50px 0px 65px 0px;
    display: flex;
    justify-content: space-between;
    background: #181818;
    padding: 8px 10px;
    .copy {
      color: #378bd3;
      font-weight: 500;
    }
  }
  .transparent {
    background-color: transparent;
  }
  .icon-box {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    span {
      text-align: center;
      padding: 0 15px;
    }
  }

  .btn-box {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    .meeting {
      margin-bottom: 10px;
    }
  }
  .input-box: {
    whitespace: "nowrap";
    overflow: "hidden";
    textoverflow: "ellipsis";
  }
  @media only screen and (max-width: 520px) {
    .btn-box {
      .meeting {
        width: 100%;
        margin-bottom: 10px;
        margin-right: 0px !important;
      }
      .invite {
        width: 100%;
        margin-bottom: 10px;
      }
    }
  }
`;

export const NameModalWrapper = styled(ShareModalWrapper)`
  .name-title {
    font-size: 16px;
  }
  .name-subtitle {
    font-size: 14px;
    padding: 25px 0px 10px 0px;
  }
  .start-pitch {
    button {
      width: 100%;
    }
  }
`;

export const ThankyouModalWrapper = styled(NameModalWrapper)`
  .thank-title {
    font-size: 16px;
  }
  .thank-subtitle {
    font-size: 14px;
    line-height: 31px;
    padding: 25px 0px 25px 0px;
  }
  .start-pitch {
    display: flex;
    button {
      width: auto;
      margin: 0 5px;
      flex: 1;
    }
  }
  @media only screen and (max-width: 520px) {
    .start-pitch {
      flex-direction: column;
      button {
        width: auto;
        margin: 8px 0;
        flex: 1;
      }
    }
  }
`;

export const EndModalWrapper = styled.div`
  //margin: 25px 100px 25px 100px;
  .transparent {
    background-color: transparent;
  }
  .next {
    width: 100%;
  }
  padding: 10px 15px;
  .title {
    font-size: 18px;
    text-align: center;
  }
  .ask-amount {
    font-size: 17px;
    padding-bottom: 10px;
    display: flex;
    justify-content: space-between;
  }
  .subtitle {
    padding: 20px 0px;
    font-size: 14px;
    text-align: center;
  }
  .btn-box {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
  }
  .content-slider {
    padding: 45px 0px;
  }
  .amount {
    display: flex;
    justify-content: space-between;
    color: #b3b3b3;
  }
  .content {
    padding: 50px 0px 60px 0px;
    font-size: 15px;
  }
  @media only screen and (max-width: 520px) {
    padding: 10px 0px;
    .btn-box {
      .meeting {
        width: 100%;
        margin-bottom: 10px;
        margin-right: 0px !important;
      }
      .invite {
        width: 100%;
        margin-bottom: 10px;
      }
    }
  }
  @media only screen and (max-width: 400px) {
    margin: 0px;
    .btn-box {
      width: 100%;
    }
  }
  @media only screen and (max-width: 1120px) {
    .btn-box {
      .more-btn {
        width: 100%;
        .ask-ques-btn {
          width: 100%;
        }
        .share-pitch-btn {
          margin-top: 14px;
          width: 100%;
        }
      }
    }
  }
  @media only screen and (max-width: 1430px) {
    .btn-box {
      width: 100%;
    }
  }
`;
