import styled from "styled-components";
export const PartnersWrap = styled.div`
  .title {
    text-align: center;
  }
  padding: 18% 0 3% 0;
  position: relative;
  background: #2e028d;
  @media only screen and (max-width: 768px) {
    padding-top: 15rem;
  }
`;
export const EaryAccessCard = styled.div`
  width: 80%;
  padding: 4% 10%;
  background: linear-gradient(214.02deg, #8e00ff 6.04%, #8256ff 92.95%);
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 10px;
  z-index: 1;
  margin: -10% auto auto auto;
  top: 0;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  .pitch-line {
    font-family: Sen;
    font-style: normal;
    font-weight: 700;
    font-size: 2.4vw;
    line-height: 3vw;
    padding-bottom: 3%;
    text-align: center;
    color: #ffffff;
  }

  @media only screen and (max-width: 768px) {
    margin: -11rem auto auto auto;
    width: 87%;
    padding: 8% 10%;
    height: auto;
    flex-direction: column;
    .pitch-line {
      font-size: 31px;
      line-height: 37px;
      padding-bottom: 1.2rem;
    }
  }
  @media only screen and (max-width: 550px) {
    padding: 10%;
  }
`;
