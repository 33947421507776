import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import ModalReducer from "./modals/reducers";
// (process.env.NODE_ENV === "development" && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose

const configureStore = createStore(
  ModalReducer,
  applyMiddleware(thunk.withExtraArgument())
);
export default configureStore;
